import { inputNodes, actionNodes, waitNodes, decisionNodes, notesNodes, exitNodes } from './NodeTypesLists';

const allNodes = [...actionNodes, ...waitNodes, ...decisionNodes, ...notesNodes, ...exitNodes, ...inputNodes];

export function checkVal(value, requirement) {
  switch (requirement) {
    case 'LENGTH':
      // Check if the value is an array or string and then check the length
      return Array.isArray(value) ? value.length > 0 : !!value;

    case 'NUMBER':
      return !isNaN(value);

    default:
      return false;
  }
}

export function validateConditionalFields(node, conditionalFields, errors, nodeId, errorField) {
  conditionalFields.forEach((conditionalField) => {
    if (
      conditionalField.condition === node.data[errorField] ||
      conditionalField.condition === node.data[errorField]?.value
    ) {
      if (conditionalField.field && node.data[conditionalField.field] !== undefined) {
        if (conditionalField.requirement) {
          const isConditionalFailed = !checkVal(node.data[conditionalField.field], conditionalField.requirement);
          if (isConditionalFailed) {
            errors.push({ id: nodeId, message: conditionalField.message });
          }
        } else if (
          Array.isArray(node.data[conditionalField.field])
            ? node.data[conditionalField.field].length === 0
            : !node.data[conditionalField.field]
        ) {
          errors.push({ id: nodeId, message: conditionalField.message });
        }
      } else {
        errors.push({ id: nodeId, message: conditionalField.message });
      }

      // Recursively validate nested conditional fields if present
      if (conditionalField.condtionalFields) {
        validateConditionalFields(node, conditionalField.condtionalFields, errors, nodeId, conditionalField.field);
      }
    }
  });
}

// Define required fields for specific actionTypes
const actionTypeRequirements = {
  EDOC_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Status is required for Edoc Status Change',
    },
  ],
  EVENT_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Status is required for Event Status Change',
    },
    {
      field: 'eventType',
      message: 'Event Types are required for Event Status Change',
      requirement: 'LENGTH',
    },
  ],
  GENERIC_FORM_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Status is required for Form Status Change',
    },
  ],
  FUNNEL_STATE_CHANGE: [
    {
      field: 'funnelId',
      message: 'Funnel is required for Funnel Status Change',
    },
    {
      field: 'stageId',
      message: 'Stage is required for Funnel Status Change',
    },
  ],
  CREATE_CANDIDATE_EVENT: [
    {
      field: 'condition',
      message: 'Condition is required for  New Candidate Event',
      condtionalFields: [
        {
          condition: 'ALL',
          field: 'funnelId',
          message: 'Value is required for New Candidate Event',
          requirement: 'LENGTH',
        },
        {
          condition: 'UTMSOURCE',
          field: 'candidateFilterVal',
          message: 'Value is required for New Candidate Event',
          requirement: 'LENGTH',
        },
        {
          condition: 'UTMCAMPAIGN',
          field: 'candidateFilterVal',
          message: 'Value is required for New Candidate Event',
          requirement: 'LENGTH',
        },
        {
          condition: 'UTMMEDIUM',
          field: 'candidateFilterVal',
          message: 'Value is required for New Candidate Event',
          requirement: 'LENGTH',
        },
      ],
    },
  ],
  ONBOARDING_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Status is required for Onboarding Status Change',
    },
  ],
  PAYROLL_INTEGRATION_SUBMISSION: [
    {
      field: 'condition',
      message: 'Status is required for Payroll Status Change',
    },
  ],
  REFEREE_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Status is required for Referee Status Change',
    },
  ],
  REFERENCE_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Status is required for Reference Status Change',
    },
  ],
  RTW_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Status is required for Right To Work Status Change',
    },
  ],
  PERSONALITY_TEST_STATUS_CHANGE: [
    {
      field: 'condition',
      message: 'Condition is required for Personality Test Status Change',
      condtionalFields: [
        {
          condition: 'RECEIVED',
          field: 'operator',
          message: 'Operator is required for Personality Test Status Change',
          condtionalFields: [
            {
              condition: 'LT',
              field: 'scoreType',
              message: 'Score Type is required for Personality Test Status Change',
              requirement: 'NUMBER',
            },
            {
              condition: 'LT',
              field: 'scoreValue',
              message: 'Score Value is required for Personality Test Status Change',
              requirement: 'NUMBER',
            },
            {
              condition: 'GT',
              field: 'scoreType',
              message: 'Score Type is required for Personality Test Status Change',
              requirement: 'NUMBER',
            },
            {
              condition: 'GT',
              field: 'scoreValue',
              message: 'Score Value is required for Personality Test Status Change',
              requirement: 'NUMBER',
            },
            {
              condition: 'EQ',
              field: 'scoreType',
              message: 'Score Type is required for Personality Test Status Change',
              requirement: 'NUMBER',
            },
            {
              condition: 'EQ',
              field: 'scoreValue',
              message: 'Score Value is required for Personality Test Status Change',
              requirement: 'NUMBER',
            },
          ],
        },
      ],
    },
  ],
  SEND_EMAIL: [
    {
      field: 'type',
      message: 'Type is required for Send Email',
      condtionalFields: [
        {
          condition: 'DIRECT',
          field: 'recipients',
          message: 'Recipients is required for Send Email',
          requirement: 'LENGTH',
        },
        {
          condition: 'ATS',
          field: 'recipients',
          message: 'Recipients is required for Send Email',
          requirement: 'LENGTH',
        },
      ],
    },
    {
      field: 'templateId',
      message: 'Template is required for Send Email',
    },
  ],
  REQUEST_ONBOARDING: [
    {
      field: 'templateId',
      message: 'Template is required for Requesting Onboarding',
    },
  ],
  REQUEST_REFEREES: [],
  SEND_TO_INTEGRATION: [
    {
      field: 'integrationType',
      message: 'Type is required for Requesting Referees',
      condtionalFields: [
        {
          condition: 'Flow',
          field: 'type',
          message: 'Recipients is required for Send To Intergration',
          requirement: 'LENGTH',
        },
      ],
    },
  ],
  REQUEST_GENERIC_FORM: [
    {
      field: 'templateId',
      message: 'Template is required for Request Form',
    },
  ],
  REQUEST_RTW: [
    {
      field: 'templateId',
      message: 'Template is required for Request Right To Work',
    },
  ],
  REQUEST_EDOC: [
    {
      field: 'templateId',
      message: 'Template is required for Request Right To Work',
    },
  ],
  REQUEST_PERSONALITY_TEST: [
    {
      field: 'templateId',
      message: 'Template is required for Request Right To Work',
    },
  ],
  UPDATE_FUNNEL_STAGE: [
    {
      field: 'funnelId',
      message: 'Funnel is required for Update Funnel',
    },
    {
      field: 'stageId',
      message: 'Stage is required for Update Funnel',
    },
  ],
  WAIT: [
    {
      field: 'number',
      message: 'number is required for Wait',
    },
    {
      field: 'range',
      message: 'range is required for Wait',
    },
  ],
};

export default function validateWorkflow(nodes, edges) {
  const errors = [];

  // Helper functions to find edges related to a node
  const findIncomingEdges = (nodeId) => edges.filter((edge) => edge.target === nodeId);
  const findOutgoingEdges = (nodeId) => edges.filter((edge) => edge.source === nodeId);

  let startNodeCount = 0;
  let endNodeCount = 0;
  const nodeIds = new Set();

  nodes.forEach((node) => {
    const foundNode = allNodes.find((inputNode) => inputNode.actionType === node.data.actionType);

    if (nodeIds.has(node.id)) {
      errors.push({ id: node.id, message: `Duplicate node ID found: ${node.id}` });
    } else {
      nodeIds.add(node.id);
    }

    const incomingEdges = findIncomingEdges(node.id);
    const outgoingEdges = findOutgoingEdges(node.id);

    if (node.type === 'startNode') {
      startNodeCount += 1;
      if (outgoingEdges.length === 0) {
        errors.push({ id: node.id, message: `Start node does not have any outgoing edges.` });
      }
    } else if (node.type === 'endNode') {
      endNodeCount += 1;
      if (incomingEdges.length === 0) {
        errors.push({ id: node.id, message: `End node does not have any incoming edges.` });
      }
    } else {
      if (incomingEdges.length === 0) {
        errors.push({ id: node.id, message: `${foundNode?.label} does not have any incoming edges.` });
      }
      if (outgoingEdges.length === 0) {
        errors.push({ id: node.id, message: `${foundNode?.label} does not have any outgoing edges.` });
      }
    }

    if (incomingEdges.length === 0 && outgoingEdges.length === 0) {
      errors.push({ id: node.id, message: `${foundNode?.label} is disconnected from the workflow.` });
    }

    if (node.type === 'decisionNode') {
      if (outgoingEdges.length !== 2) {
        errors.push({
          id: node.id,
          message: `${foundNode?.label} must have exactly two outgoing edges.`,
        });
      }

      if (!node.data.conditions?.length) {
        errors.push({
          id: node.id,
          message: `${foundNode?.label} must have at least one condition.`,
        });
      } else {
        node.data.conditions.forEach((condition) => {
          if (condition.value === 'applicationQuestion') {
            if (!condition?.condition?.answer || !condition?.condition?.question) {
              errors.push({
                id: node.id,
                message: `${foundNode?.label} - Application Question is missing some values`,
              });
            }
          } else {
            if (!condition.condition || !condition.value) {
              errors.push({ id: node.id, message: `${foundNode?.label} - Condition is missing some values` });
            }
          }
        });
      }
    }

    const actionType = node.data?.actionType;
    if (actionType && actionTypeRequirements[actionType]) {
      const requiredFields = actionTypeRequirements[actionType];

      requiredFields.forEach((error) => {
        if (error.field && node.data[error.field] !== undefined) {
          if (error.requirement) {
            const isFailed = !checkVal(node.data[error.field], error.requirement);
            if (isFailed) {
              errors.push({ id: node.id, message: error.message });
            }
          } else if (
            Array.isArray(node.data[error.field]) ? node.data[error.field].length === 0 : !node.data[error.field]
          ) {
            errors.push({ id: node.id, message: error.message });
          }
        } else {
          errors.push({ id: node.id, message: error.message });
        }

        // Check for conditional fields recursively
        if (error.field && node.data[error.field] && error.condtionalFields) {
          validateConditionalFields(node, error.condtionalFields, errors, node.id, error.field);
        }
      });

      if (actionType === 'WAIT') {
        const numberValue = parseFloat(node.data.number);
        if (isNaN(numberValue) || numberValue <= 0) {
          errors.push({ id: node.id, message: `${foundNode?.label} has an invalid 'number' value.` });
        }
        const validRanges = ['min', 'hour', 'day'];
        if (!validRanges.includes(node.data.range)) {
          errors.push({
            id: node.id,
            message: `${foundNode?.label} has an invalid 'range' value. It must be one of ${validRanges.join(', ')}.`,
          });
        }
      }
    }
  });

  if (startNodeCount !== 1) {
    errors.push({
      id: 'startNodeValidation',
      message: `There must be exactly one start node.`,
    });
  }

  if (endNodeCount < 1) {
    errors.push({ id: 'endNodeValidation', message: `There must be at least one end node.` });
  }

  edges.forEach((edge) => {
    const sourceNode = nodes.find((node) => node.id === edge.source);
    const targetNode = nodes.find((node) => node.id === edge.target);
    if (!sourceNode) {
      errors.push({ id: edge.id, message: `Edge '${edge.id}' has a non-existent source node: ${edge.source}` });
    }
    if (!targetNode) {
      errors.push({ id: edge.id, message: `Edge '${edge.id}' has a non-existent target node: ${edge.target}` });
    }

    if ((edge.type === 'yesEdge' || edge.type === 'noEdge') && sourceNode?.type !== 'decisionNode') {
      errors.push({ id: edge.id, message: `Edge '${edge.id}' connects to an invalid node type for this edge.` });
    }
  });

  return errors;
}
