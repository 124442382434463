/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-for */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import { toast } from 'react-toastify';
import * as utils from '../utils';
import { eventInputConfig, EventInputs, InviteEmailContext, ReminderDaysContext, VideoRoomContext } from './inputs';
import { CancelButton, CreateButton, DeleteButton } from '../../Base/Buttons';
import RecurrenceHeading from './RecurrenceHeading';
import { retryableAPICall } from '../../../api/common-api-utils';
import { SectionToggle } from '../../Base/SectionDividers';
import { createSlot, updateSlot } from '../../../api/SchedulingAPI/CalendarAPI';
import { inviteToInterview } from '../../../api/SchedulingAPI/InterviewAPI';
import { useFormKeypress } from '../../Base/hooks';
import BookingStatus from './BookingStatus';
import Can from '../../Base/RBAC/Can/Can';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import EditingContext from './EditingContext';
import { LocationsContext } from '../../Admin/Client/Locations';

const RestrictedDeleteButton = Can(DeleteButton);
const RestrictedCreateButton = Can(CreateButton);
const RestrictedCancelButton = Can(CancelButton);

async function proxyCall(data, isInvite, isUpdate, accountId) {
  if (isInvite) {
    const { invites } = data;
    if (invites && Object.keys(invites).length) return inviteToInterview(invites);
  }

  if (isUpdate) return updateSlot(data);

  return createSlot(data, accountId);
}

function mergeData(dataObjA, dataObjB) {
  return utils.dateTimeUtils.mergeDateTime({
    ...dataObjA,
    ...dataObjB,
  });
}

function getButtonText(isEditable, isEditing) {
  let buttonText = 'Create Event';
  if (isEditable) {
    if (isEditing) {
      buttonText = 'Update';
    } else {
      buttonText = 'Invite Candidates';
    }
  }
  return buttonText;
}

function getSaveBtnText(isSaving, isEditable, isEditing, buttonDefaultText) {
  let saveBtnText = buttonDefaultText;
  if (isSaving) {
    if (isEditable) {
      if (isEditing) {
        saveBtnText = 'Updating...';
      } else {
        saveBtnText = 'Inviting...';
      }
    } else {
      saveBtnText = 'Creating...';
    }
  }
  return saveBtnText;
}
function EventEditor({
  eventObj,
  isCopying,
  isEditable,
  isEditing,
  onSave,
  onCancel,
  onDelete,
  activeAccountId,
  eventTypeId,
  isPrivateEvent,
}) {
  const formRef = useFormKeypress();
  const [formData, setFormData] = useState({});
  const [appointmentCollapsed, setAppointmentCollapsed] = useState(true);
  const [invitesCollapsed, setInvitesCollapsed] = useState(true);
  const [extrasCollapsed, setExtrasCollapsed] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [eventFormat, setEventFormat] = useState();
  const cachedEventObj = useRef({});
  const newEvent = isEditable && !isCopying;

  useEffect(() => {
    let eventData = eventObj;

    // if not editing and there is cached data call this as data.
    // Allows cancelation of edit and overwrite changes
    if (!isEditing && Object.keys(cachedEventObj.current).length) eventData = cachedEventObj.current;

    const data = utils.dataUtils.getFormData(eventData, newEvent);
    setFormData(data);
    if (data.videoRoomId) setEventFormat('VIDEO');

    // if editable and no cache of data cache data so user
    // can change their mind and not have to close editor
    if (newEvent && !Object.keys(cachedEventObj.current).length) cachedEventObj.current = eventObj;
  }, [eventObj, newEvent, isEditing]);

  useEffect(() => {
    if (isCopying) {
      setAppointmentCollapsed(false);
    }
  }, [isCopying]);

  useEffect(() => {
    const updateEventType = async () => {
      const eventTypeObj = await utils.dataUtils.eventTypeEmailDetailsObject(eventTypeId);
      const { eventName, eventDescription, emailDetails = {}, eventFormat: evtFormat } = eventTypeObj;

      setEventFormat(evtFormat);
      setFormData((prevData) => {
        return mergeData(prevData, {
          eventName,
          eventDescription,
          eventTypeId,
          inviteOnly: isPrivateEvent ? 'true' : 'false',
          ...(Object.keys(emailDetails).length
            ? {
                emailDetails,
              }
            : {}),
        });
      });
    };

    if (eventTypeId) updateEventType();
  }, [eventTypeId]);

  async function handleChange(id, val, inpConfig) {
    if (submitted) {
      const inpErrs = utils.eventUtils.validateEventData([inpConfig], { [id]: val });
      setErrors({ ...errors, ...inpErrs });
    }

    let updatedData = mergeData(formData, {
      [id]: val,
      ...(id === 'recurrenceType' ? { recurringEvent: val !== 'null' } : {}),
    });

    setFormData(updatedData);

    // when event type changed
    if (id === 'eventTypeId') {
      const eventTypeObj = await utils.dataUtils.eventTypeEmailDetailsObject(val);
      const { eventName, eventDescription, emailDetails = {}, eventFormat: evtFormat } = eventTypeObj;

      updatedData = mergeData(updatedData, {
        eventName,
        eventDescription,
        ...(Object.keys(emailDetails).length
          ? {
              emailDetails,
            }
          : {}),
      });

      setEventFormat(evtFormat);
      setFormData(updatedData);
    }
  }

  async function handleSave() {
    setIsSaving(true);
    setSubmitted(true);

    // flatten config object into single array
    let inputs = Object.values(eventInputConfig).reduce((acc, input) => [...acc, ...input], []);
    // if VIDEO
    if (eventFormat === 'VIDEO') {
      // Only allow one candidate
      inputs = inputs.map((input) => (input.id === 'numSpaces' ? { ...input, max: 1 } : input));
    }

    const errObj = utils.eventUtils.validateEventData(inputs, formData, newEvent && !isEditing);
    setErrors(errObj);

    const hasErrors = Object.values(errObj).some(({ invalid }) => invalid);

    if (!hasErrors) {
      let serverEventData = utils.dataUtils.serverEvent({ ...formData }, isEditing);

      // We don't want to send the id when copying an event so it will create a new one.
      if (isCopying) {
        delete serverEventData.id;
      }

      const isSlotDuration = formData.slotDuration && formData.slotDuration !== 'FULL';
      const hasCandidates = formData.candidateIds && formData.candidateIds.length > 0;

      if (isSlotDuration && !hasCandidates && !isEditing) {
        const slotDurationInMinutes = formData.slotDuration; // Assume this is in minutes

        // Parse start and end times as moment objects
        const currentStartTime = moment(serverEventData.startDate);
        const currentEndTime = moment(serverEventData.endDate);
        const totalDurationInMinutes = currentEndTime.diff(currentStartTime, 'minutes'); // Total duration in minutes

        const numSlots = Math.ceil(totalDurationInMinutes / slotDurationInMinutes);

        const slots = [];

        // Create smaller slots
        for (let i = 0; i < numSlots; i++) {
          // Calculate start and end times for each slot
          const slotStart = moment(currentStartTime).add(i * slotDurationInMinutes, 'minutes');
          const slotEnd = moment(slotStart).add(slotDurationInMinutes, 'minutes');

          // Clone the event data and update start/end times using moment formatting
          const slotData = { ...serverEventData };

          // Ensure startDate and endDate remain in the correct ISO format
          slotData.startDate = slotStart.format('YYYY-MM-DD HH:mm:ss'); // Ensure ISO format
          slotData.endDate = slotEnd.format('YYYY-MM-DD HH:mm:ss'); // Ensure ISO format

          // Format startTime and endTime to be in 24-hour format (HH:mm)
          slotData.startTime = slotStart.format('HH:mm'); // 24-hour format for startTime
          slotData.endTime = slotEnd.format('HH:mm'); // 24-hour format for endTime

          slots.push(slotData);
        }

        // Make API call for each slot
        const slotPromises = slots.map((slot) =>
          retryableAPICall(() => proxyCall(slot, newEvent && !isEditing, isEditing, activeAccountId)),
        );

        const responses = await Promise.all(slotPromises);
        const failedResponses = responses.filter(
          (resp) => typeof resp === 'string' || (Array.isArray(resp) && !resp.length),
        );

        if (failedResponses.length > 0) {
          toast.error(
            newEvent ? (isEditing ? 'Error updating event' : 'Error sending invite') : 'Error creating event',
          );
        } else {
          onSave(responses.flat());
        }
      } else {
        const resp = await retryableAPICall(() =>
          proxyCall(serverEventData, newEvent && !isEditing, isEditing, activeAccountId),
        );
        if (typeof resp === 'string' || (Array.isArray(resp) && !resp.length)) {
          toast.error(
            newEvent ? (isEditing ? 'Error updating event' : 'Error sending invite') : 'Error creating event',
          );
        } else {
          onSave(resp);
        }
      }

      setSubmitted(false);
    } else {
      // for each config object
      Object.entries(eventInputConfig).forEach(([key, inpArr]) => {
        // if find errors in section
        const sectionHasErrors = inpArr.some(({ id }) => errObj[id] && errObj[id].invalid);

        // if errors
        if (sectionHasErrors) {
          // show the section
          if (key === 'appointment') setAppointmentCollapsed(false);
          if (key === 'extras') setExtrasCollapsed(false);
          if (key === 'invites') setInvitesCollapsed(false);
        }
      });
    }

    setIsSaving(false);
  }

  const buttonDefaultText = getButtonText(newEvent, isEditing);
  const saveBtnText = getSaveBtnText(isSaving, newEvent, isEditing, buttonDefaultText);

  return (
    <>
      <Form className="event-form" innerRef={formRef}>
        <SectionToggle
          sectionAs="fieldset"
          heading={() => (
            <>
              <h5 className={!formData.recurringEvent ? 'mb-0' : ''}>{utils.eventUtils.getEventTitle(formData)}</h5>
              <RecurrenceHeading
                recurrence={formData.recurrenceType}
                startDate={formData.recurrenceStartDate || formData.startDate}
                endDate={formData.recurrenceEndDate}
              />
            </>
          )}
          sectionClassName="event-appointment"
          isCollapsed={appointmentCollapsed}
          onToggle={() => setAppointmentCollapsed(!appointmentCollapsed)}
        >
          <EventInputs
            configs={eventInputConfig.appointment}
            formData={formData}
            errors={errors}
            onChange={handleChange}
            isEditable={newEvent}
            isEditing={isEditing}
            activeAccountId={activeAccountId}
          />
        </SectionToggle>
        <hr />
        {newEvent && !isEditing && (
          <>
            <h6>Candidates</h6>
            <fieldset className="event-candidates">
              <EditingContext.Provider value={{ isEditable: newEvent, slotId: formData.id }}>
                <BookingStatus slotId={formData.id} bookedInCandidates={formData.bookedInCandidates} />
              </EditingContext.Provider>
            </fieldset>
            <hr />
          </>
        )}
        <fieldset className="event-details">
          <VideoRoomContext.Provider
            value={{
              videoRoomId: formData.videoRoomId,
              bookedInCandidates: formData.bookedInCandidates,
            }}
          >
            <LocationsContext.Provider value={{ eventFormat }}>
              <EventInputs
                configs={eventInputConfig.details}
                eventFormat={eventFormat}
                formData={formData}
                errors={errors}
                onChange={handleChange}
                isEditable={newEvent}
                isEditing={isEditing}
                activeAccountId={activeAccountId}
              />
            </LocationsContext.Provider>
          </VideoRoomContext.Provider>
        </fieldset>
        <hr />
        <SectionToggle
          sectionAs="fieldset"
          heading="More Event Details"
          sectionClassName="event-extras"
          isCollapsed={extrasCollapsed}
          onToggle={() => setExtrasCollapsed(!extrasCollapsed)}
        >
          <EventInputs
            configs={eventInputConfig.extras}
            formData={formData}
            errors={errors}
            onChange={handleChange}
            isEditable={newEvent}
            isEditing={isEditing}
            activeAccountId={activeAccountId}
          />
        </SectionToggle>
        <hr />
        {checkPermissions(['candidate:interview:create']) && (
          <>
            <SectionToggle
              sectionAs="fieldset"
              heading="Invite Candidates"
              sectionClassName="invite-candidates"
              isCollapsed={invitesCollapsed}
              onToggle={() => setInvitesCollapsed(!invitesCollapsed)}
            >
              <InviteEmailContext.Provider
                value={{
                  applicants: [],
                  removeInputs: ['to'],
                }}
              >
                <ReminderDaysContext.Provider
                  value={{
                    startDate: formData.startDate,
                    inviteType: formData.inviteType,
                  }}
                >
                  <EventInputs
                    noTemplates={true}
                    configs={eventInputConfig.invites}
                    formData={formData}
                    errors={errors}
                    onChange={handleChange}
                    isEditable={newEvent}
                    isEditing={isEditing}
                    activeAccountId={activeAccountId}
                  />
                </ReminderDaysContext.Provider>
              </InviteEmailContext.Provider>
            </SectionToggle>
            <hr />
          </>
        )}
        {isEditing && (
          <>
            <RestrictedDeleteButton
              permissions={['scheduler:calendar:delete']}
              floatRight={false}
              className="me-2"
              action={() => onDelete(formData.id, formData.bookedInCandidates)}
            />
            {formData.recurringEvent && (
              <RestrictedDeleteButton
                permissions={['scheduler:calendar:delete']}
                label="Delete Recurring Events"
                floatRight={false}
                action={() => onDelete(formData.recurrenceGroupingId, formData.bookedInCandidates, true)}
              />
            )}
          </>
        )}
        <RestrictedCreateButton
          permissions={['scheduler:calendar:update']}
          action={(e) => {
            e.preventDefault();
            handleSave();
          }}
          isLoading={isSaving}
          disabled={isSaving}
          label={saveBtnText}
        />
        <RestrictedCancelButton permissions={['scheduler:calendar:update']} action={onCancel} disabled={isSaving} />
      </Form>
    </>
  );
}

EventEditor.propTypes = {
  eventObj: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    allDay: PropTypes.bool,
  }),
  isCopying: PropTypes.bool,
  isEditable: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  activeAccountId: PropTypes.string,
};

EventEditor.defaultProps = {
  eventObj: {},
  isCopying: false,
  isEditable: false,
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
  onDelete: () => {},
  activeAccountId: null,
};

export default connect()(EventEditor);
