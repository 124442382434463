import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function ToolTipContent({ videoRoomId, bookedInCandidates, inviteIdParam }) {
  const hasCandidates = !!bookedInCandidates.length;

  return (
    <Fragment>
      {hasCandidates && (
        <Fragment>
          <p>{`Candidate${bookedInCandidates.length > 1 ? 's' : ''}:`}</p>
          {bookedInCandidates.map(({ candidateId, firstName = '', surname = '' }) => (
            <p key={candidateId}>{`${firstName} ${surname}`}</p>
          ))}
        </Fragment>
      )}
      <p className={cx('text-center', { 'mt-2 pt-2 border-top border-white': hasCandidates })}>
        <a
          style={{ color: 'white' }}
          href={`/video/interview?id=${videoRoomId}${inviteIdParam}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to video meeting
        </a>
      </p>
    </Fragment>
  );
}

ToolTipContent.propTypes = {
  videoRoomId: PropTypes.string,
  bookedInCandidates: PropTypes.arrayOf(PropTypes.shape()),
  inviteIdParam: PropTypes.string,
};

ToolTipContent.defaultProps = {
  videoRoomId: '',
  bookedInCandidates: [],
  inviteIdParam: '',
};

export default ToolTipContent;
