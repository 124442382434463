import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import { isEmail } from 'react-multi-email';
import { components } from 'react-select';
import { connect } from 'react-redux';

import { findUsersForAccount } from '../../../../../api/AccountAPI';

function SingleEmailInput({ value, onChange, errorMessage, activeAccountId }) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    const fetchEmails = async () => {
      const result = await findUsersForAccount(null, 'all');

      if (typeof result === 'string') {
        toast.error('Could not load users for assigning vacancy to. Please try again later');
      } else {
        const emailOptions = result.map((val) => ({
          label: `${val.name} - ${val.email}`,
          value: val.email,
        }));
        setOptions(emailOptions);
      }
    };
    fetchEmails();
  }, [activeAccountId]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue && isEmail(inputValue)) {
      onChange(inputValue);
      setInputValue('');
      setMenuIsOpen(false);
    }
  };

  const handleSelectChange = (selectedOption) => {
    const selectedEmail = selectedOption ? selectedOption.value : null;
    onChange(selectedEmail);
    setMenuIsOpen(false);
  };

  const CustomInput = (props) => (
    <components.Input
      {...props}
      inputRef={inputRef}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
        if (!menuIsOpen) {
          setMenuIsOpen(true);
        }
      }}
      onKeyDown={handleKeyDown}
      autoFocus={menuIsOpen}
    />
  );

  return (
    <>
      <CreatableSelect
        isMulti={false}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => {
          setMenuIsOpen(false);
          setInputValue('');
        }}
        options={options}
        value={value ? { label: value, value: value } : null}
        onChange={handleSelectChange}
        components={{ Input: CustomInput }}
        inputValue={inputValue}
        isValidNewOption={(inputValue) => isEmail(inputValue)}
        onInputChange={(input, actionMeta) => {
          if (actionMeta.action === 'input-change') {
            setInputValue(input);
            setMenuIsOpen(true);
          }
          if (actionMeta.action === 'set-value') {
            setInputValue('');
          }
        }}
        placeholder="Search or add email by typing..."
        noOptionsMessage={() => 'Enter a valid email to add e.g. user@es4.co.uk'}
        className={classNames({ ['is-invalid']: errorMessage })}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </>
  );
}

SingleEmailInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
};

SingleEmailInput.defaultProps = {
  value: '',
  onChange: () => {},
  errorMessage: null,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: { activeAccountId },
    },
  } = state;

  return {
    activeAccountId,
  };
}

export default connect(mapStateToProps)(SingleEmailInput);
