import {
  REMOVE_SLOT_BOOKING,
  ADD_SLOT_BOOKING_CANDIDATES,
  REMOVE_SLOT_BOOKING_CANDIDATES,
} from '../constants/actionTypes';

export function addBookingCandidates(id, candidateIds) {
  return { type: ADD_SLOT_BOOKING_CANDIDATES, payload: { id, candidateIds } };
}

export function removeBookingCandidates(id, candidateIds) {
  return { type: REMOVE_SLOT_BOOKING_CANDIDATES, payload: { id, candidateIds } };
}

export function removeSlot(id) {
  return { type: REMOVE_SLOT_BOOKING, payload: { id } };
}
