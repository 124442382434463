// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import { TextButton } from '../../Base/Buttons';
import { getFormPlaceHolders } from '../../../api/FormsAPI/ATSFormsAPI';
import EmailForm from '../../Communication/email/EmailForm';
import DatePickerInput from '../../Base/Forms/Custom/DatePicker/DatePickerInput';
import FormsSelector from '../Other/FormsSelector';
import { ModalPopup } from '../../Base/Modal';
import { deleteEDocFromRequest } from '../../../api/CandidateAPI/EDocAPI';

function EDocRequestCreation({
  candidate,
  existingEDocRequests,
  vacancyTitle,
  languagePack,
  onUpdate,
  doEDocRequestRefresh,
  vacancyLocation,
  vacancyPostCode,
  employerEmail,
  employerName,
}) {
  const [selectedToRequestEDocs, setSelectedToRequestEDocs] = useState([]);
  const [showEmailTemplateInput, setShowEmailTemplateInput] = useState(false);
  const [eDocParams, setEDocParams] = useState({});
  const [eDocParamInputs, setEDocParamInputs] = useState([]);
  const [eDocParamValues, setEDocParamValues] = useState({});
  const [eDocParamInputIds, setEDocParamInputIds] = useState([]);
  const [selectedDocExistsList, setSelectedDocExistsList] = useState([]);
  const [showDocAlreadySentModal, setShowDocAlreadySentModal] = useState(false);
  const formSelectorRef = React.useRef(null);

  function toggleReqInfo() {
    setSelectedToRequestEDocs([]);
    setShowEmailTemplateInput(false);
    setEDocParamInputs([]);
    setEDocParamInputIds([]);
    setEDocParamValues({});
    setEDocParams({});
    setEDocParamInputIds([]);
  }

  function handleSuccess(message) {
    toggleReqInfo();
    toast.success(languagePack.successfullySentDocumentRequest);
    onUpdate(message, () => {});
  }

  async function loadContractParams(selectedValues) {
    if (selectedValues && selectedValues.length > 0) {
      const selectedValLabels = selectedValues.map((sv) => sv.label);
      const eDocParamz = { ...eDocParams };

      Object.keys(eDocParamz).forEach((key) => {
        if (!selectedValLabels.includes(key)) {
          delete eDocParamz[key];
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const selVal of selectedValues) {
        if (!eDocParams[selVal.label]) {
          // eslint-disable-next-line no-await-in-loop
          eDocParamz[selVal.label] = await getFormPlaceHolders(selVal.id);
        }
      }
      setEDocParams(eDocParamz);
    } else {
      setEDocParams({});
    }
  }

  function paramInputOnChange(id, val) {
    const paramObj = { ...eDocParamValues };
    paramObj[id] = val || '';
    setEDocParamValues(paramObj);
  }

  useEffect(() => {
    setShowEmailTemplateInput(false);
    loadContractParams(selectedToRequestEDocs);
  }, [selectedToRequestEDocs]);

  function onEDocFormSelectChange(selectedVal, allValues) {
    const mappedAllValues = allValues.map((val) => {
      // clean data here
      return { id: val.value, label: val.label, value: val.value };
    });
    const alreadyExistsMatches = [];
    const selValsIds = mappedAllValues.map((itm) => itm.id);
    const removedVals = selectedToRequestEDocs.filter((selEDoc) => !selValsIds.includes(selEDoc.id));

    console.log(`mappedAllValues: ${JSON.stringify(mappedAllValues)}`);
    console.log(`existingEDocRequests: ${JSON.stringify(existingEDocRequests)}`);
    mappedAllValues.forEach((val) => {
      existingEDocRequests.forEach((request) => {
        const alreadyExists = request.edocs.filter((eDoc) => {
          return eDoc.sourceFlexiFormViewId === val.id && eDoc.status !== 'WITHDRAWN';
        });

        if (alreadyExists.length > 0) {
          alreadyExists.forEach((existingEDoc) => {
            alreadyExistsMatches.push({
              eDocId: existingEDoc.id,
              name: existingEDoc.name,
              requestId: request.id,
              sourceFlexiFormViewId: existingEDoc.sourceFlexiFormViewId,
            });
          });
        }
      });
    });

    setSelectedDocExistsList(alreadyExistsMatches);
    setSelectedToRequestEDocs(mappedAllValues);

    if (alreadyExistsMatches.length > 0) {
      setShowDocAlreadySentModal(true);
    }

    if (removedVals.length > 0) {
      let items = { ...eDocParamValues };

      removedVals.forEach((remVal) => {
        const keylabel = remVal.label + ':';

        Object.keys(items).forEach((key) => {
          if (key.startsWith(keylabel)) {
            delete items[key];
          }
        });
      });

      setEDocParamValues(items);
      const e = { ...eDocParams };
      removedVals.forEach((remVal) => {
        delete e[remVal.label];
      });
      setEDocParams(e);
    }
  }

  function resolveCommonPlaceholders(placeholder, inputId) {
    switch (placeholder) {
      case 'candidate_first_name':
        if (inputId) paramInputOnChange(inputId, candidate.applicantProfile.personalDetails.givenName);
        return candidate.applicantProfile.personalDetails.givenName;
      case 'candidate_surname':
        if (inputId) paramInputOnChange(inputId, candidate.applicantProfile.personalDetails.familyName);
        return candidate.applicantProfile.personalDetails.familyName;
      case 'candidate_email_address':
        if (inputId) paramInputOnChange(inputId, candidate.applicantProfile.communication.emailAddress);
        return candidate.applicantProfile.communication.emailAddress;
      case 'candidate_phone_number':
        if (inputId) paramInputOnChange(inputId, candidate.applicantProfile.communication.phone.mobilePhone);
        return candidate.applicantProfile.communication.phone.mobilePhone;
      case 'vacancy_title':
        if (inputId) paramInputOnChange(inputId, vacancyTitle);
        return vacancyTitle;
      case 'employer_name':
        if (inputId) paramInputOnChange(inputId, employerName);
        return employerName;
      case 'todays_date': {
        const today = moment();
        if (inputId) paramInputOnChange(inputId, today);
        return today;
      }
      case 'vacancy_location':
        if (inputId) paramInputOnChange(inputId, vacancyLocation);
        return vacancyLocation;
      case 'candidate_postal_address':
        if (inputId)
          paramInputOnChange(inputId, candidate?.applicantProfile?.communication?.postalAddress?.formattedAddress);
        return candidate?.applicantProfile?.communication?.postalAddress?.formattedAddress;
      case 'vacancy_postcode':
        if (inputId) paramInputOnChange(inputId, vacancyPostCode);
        return vacancyPostCode;
      case 'employer_email_address':
        if (inputId) paramInputOnChange(inputId, employerEmail);
        return employerEmail;
      default:
        return '';
    }
  }

  function buildFormParameterInputs() {
    const formParamInputs = [];
    const inputIds = [];
    Object.entries(eDocParams).forEach(([formName, sections]) => {
      formParamInputs.push(
        <Row>
          <Col>
            <h4>{formName}</h4>
          </Col>
        </Row>,
      );
      Object.entries(sections).forEach(([sectionName, parameters]) => {
        formParamInputs.push(
          <Row>
            <Col>
              <h5>{sectionName}</h5>
            </Col>
          </Row>,
        );
        const uniqueParams = [...new Set(parameters)];
        uniqueParams.forEach((param) => {
          const inputId = `${formName}:${param}`;
          inputIds.push(inputId);
          formParamInputs.push(
            <Row className="mb-1">
              <Col className="text-capitalize" xs="4">
                {param.replaceAll('_', ' ')}
                <span className="required">*</span>
              </Col>
              <Col xs="8">
                {inputId.includes('_date') && (
                  <DatePickerInput
                    id={inputId}
                    onChange={(val) => paramInputOnChange(inputId, val)}
                    pastDates={false}
                    placeholder="Date"
                    showClearDate
                    value={
                      eDocParamValues[inputId] ? eDocParamValues[inputId] : resolveCommonPlaceholders(param, inputId)
                    }
                  />
                )}
                {!inputId.includes('_date') && (
                  <Input
                    id={inputId}
                    onChange={(val) => {
                      const newVal = val.currentTarget.value?.replace(/[\t\n\u00A0\u200B\u200C\u200D]|&nbsp;/g, ' ');
                      return paramInputOnChange(inputId, newVal);
                    }}
                    type="text"
                    value={
                      eDocParamValues[inputId] || eDocParamValues[inputId] === ''
                        ? eDocParamValues[inputId]
                        : resolveCommonPlaceholders(param, inputId)
                    }
                  />
                )}
              </Col>
            </Row>,
          );
        });
      });
    });
    setEDocParamInputIds(inputIds);
    setEDocParamInputs(formParamInputs);
  }

  function removeSelectedEDoc(id) {
    const newSelectedList = selectedToRequestEDocs.filter((selEDoc) => {
      return id !== selEDoc.id;
    });
    setSelectedToRequestEDocs([...newSelectedList]);
  }

  useEffect(() => {
    buildFormParameterInputs();
  }, [eDocParams, eDocParamValues]);

  async function handleEDocDelete(val) {
    const result = await deleteEDocFromRequest(val.requestId, val.eDocId);

    if (result !== null) {
      doEDocRequestRefresh();
    } else {
      toast.error(languagePack.deleteEDocFailureMessage);
    }

    setShowDocAlreadySentModal(false);
  }

  const selEFormIds = {};
  selectedToRequestEDocs.forEach((edoc) => {
    selEFormIds[edoc.label] = edoc.id;
  });

  const disableSend =
    (eDocParamInputIds.filter((inputId) => !Object.keys(eDocParamValues).includes(inputId)).length > 0 &&
      selectedToRequestEDocs.length > 0) ||
    Object.keys(selEFormIds).length === 0;

  return (
    <>
      <div className="mb-1">
        <FormsSelector
          formSelectorRef={formSelectorRef}
          languagePack={languagePack}
          onChange={onEDocFormSelectChange}
          selectedValues={selectedToRequestEDocs}
          type="EDOC"
        />
        {eDocParamInputs}
        <Row>
          <Col>
            <TextButton
              action={() => {
                // We have to remove all of the whitespace from the values
                if (eDocParamValues) {
                  const cleanedObject = {};

                  Object.keys(eDocParamValues).forEach((key) => {
                    cleanedObject[key] =
                      typeof eDocParamValues[key] === 'string' ? eDocParamValues[key].trim() : eDocParamValues[key];
                  });

                  setEDocParamValues(cleanedObject);
                }

                setShowEmailTemplateInput(!showEmailTemplateInput);
              }}
              disabled={disableSend}
              label={languagePack.composeDocumentReqEmailButtonLabel}
            />
          </Col>
        </Row>

        {showEmailTemplateInput && !disableSend && (
          <Row>
            <Col>
              <EmailForm
                actionType="EDOC"
                applicants={[
                  {
                    applicantEmail: candidate.applicantProfile.communication.emailAddress,
                    applicantId: candidate.id,
                    applicantName: `${candidate.applicantProfile.personalDetails.givenName}
                      ${candidate.applicantProfile.personalDetails.familyName}`,
                  },
                ]}
                disableSend={disableSend}
                docFormIds={selEFormIds}
                onError={() => {
                  toast.error(languagePack.errorSendingDocumentRequest);
                }}
                onSuccess={(e, resp) => handleSuccess(e, resp)}
                parameters={eDocParamValues}
                sendBtnLabel={languagePack.requestDocumentButtonLabel}
              />
            </Col>
          </Row>
        )}
      </div>
      <ModalPopup
        closeOnOkay
        isOpen={showDocAlreadySentModal}
        okayLabel={languagePack.eDocumentDeleteButtonLabel}
        onCancel={() => {
          removeSelectedEDoc(selectedDocExistsList[0]?.sourceFlexiFormViewId);
          setShowDocAlreadySentModal(false);
        }}
        onOkay={() => handleEDocDelete(selectedDocExistsList[0])}
        title={`${selectedDocExistsList[0]?.name} ${languagePack.eDocumentAlreadySentWarningTitle}`}
      >
        {`${selectedDocExistsList[0]?.name} ${languagePack.eDocumentAlreadySentWarningDocNameSuffix}`}
      </ModalPopup>
    </>
  );
}

EDocRequestCreation.propTypes = {
  candidate: PropTypes.shape(),
  doEDocRequestRefresh: PropTypes.func,
  existingEDocRequests: PropTypes.arrayOf(PropTypes.shape()),
  languagePack: PropTypes.shape(),
  onUpdate: PropTypes.func,
  vacancyTitle: PropTypes.string,
  vacancyLocation: PropTypes.shape(),
  employerName: PropTypes.string,
  vacancyPostCode: PropTypes.string,
  employerEmail: PropTypes.string,
};

EDocRequestCreation.defaultProps = {
  candidate: null,
  doEDocRequestRefresh: () => {},
  existingEDocRequests: [],
  languagePack: {},
  onUpdate: () => {},
  vacancyTitle: null,
  vacancyLocation: null,
  employerName: null,
  vacancyPostCode: null,
  employerEmail: null,
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

export default connect(mapStateToProps, null)(EDocRequestCreation);
