import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalPopup } from '../../../../Base/Modal';
import ModalScheduleView from './ModalScheduleView';
import classNames from 'classnames';
import styled from 'styled-components';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { getEventAvailablity } from '../../../../../api/SchedulingAPI/SlotAPI';
import moment from 'moment';
import { Loader } from '../../../../Base/Loading';
import { useMounted } from '../../../../Base/hooks';

const StyledSelectWrapper = styled.div`
  display: flex;
`;

const StyledAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1.2rem;
  margin: auto;
  margin-top: 0.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid lightgray;
  color: rgb(26, 46, 86);
  font-size: 0.9rem;
  transition: all 0.3s ease;

  a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
    color: #007bff;
    transition: color 0.3s ease;

    &:hover {
      color: black;
      font-weight: 800;
    }
  }
`;

function CreateCalendarSlot({ formData, applicants, onChange, applicantActiveAccountId, isModalOpen, setIsModalOpen }) {
  const [eventAvailablityCount, setEventAvailablityCount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMounted();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const today = moment().format('YYYY-MM-DD HH:mm:ss');
      const twoWeeks = moment().add(2, 'weeks').format('YYYY-MM-DD HH:mm:ss');

      const response = await retryableAPICall(() =>
        getEventAvailablity(formData.eventTypeId, today, twoWeeks, applicantActiveAccountId),
      );

      if (isMounted()) {
        if (typeof response === 'string') {
          setEventAvailablityCount(0);
        } else {
          const numberOfSlots = response.reduce((acc, curr) => acc + curr.remainingSpaces, 0);
          setEventAvailablityCount(numberOfSlots);
        }
        setIsLoading(false);
      }
    };

    if (formData.eventTypeId && !isModalOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, formData.eventTypeId, isModalOpen]);

  if (isLoading) {
    return <Loader size="sm" />;
  }

  let messsage;
  let messageLink;

  if (typeof eventAvailablityCount === 'number') {
    messsage = 'There are no free slots for this event. ';
    messageLink = 'Create one?';
    if (eventAvailablityCount > 1) {
      messsage = `There are ${eventAvailablityCount} slots available for this event. `;
      messageLink = 'Create another?';
    } else if (eventAvailablityCount === 1) {
      messsage = `There is 1 slot available for this event. `;
      messageLink = 'Create another?';
    }
  }

  return (
    <StyledSelectWrapper>
      {formData.inviteType === 'INSTANT' ||
      formData.inviteType === 'SPECIFIC' ||
      formData.eventTypeId === null ||
      formData.eventTypeId === '' ||
      !messsage ? null : (
        <StyledAlert>
          <span className="me-1">{messsage}</span>
          <a
            className="text-primary"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
              onChange();
            }}
          >
            {messageLink}
          </a>
        </StyledAlert>
      )}
      <ModalPopup
        hideOkayButton
        hideCancelButton
        isOpen={isModalOpen}
        onToggle={() => {
          setIsModalOpen(false);
          onChange();
        }}
        title="Create Slot"
        className="mt-n1"
        size="xl"
      >
        {({ modalRef }) => {
          return (
            <ModalScheduleView
              modalRef={modalRef}
              eventTypeId={formData.eventTypeId}
              applicants={applicants || []}
              isPrivateEvent={formData.inviteType === 'SPECIFIC'}
              applicantActiveAccountId={applicantActiveAccountId}
              onEventCreated={() => {
                setIsModalOpen(false);
                onChange();
              }}
            />
          );
        }}
      </ModalPopup>
    </StyledSelectWrapper>
  );
}

CreateCalendarSlot.propTypes = {
  activeAccountId: PropTypes.string,
  disabled: PropTypes.bool,
  eventTypeId: PropTypes.string,
  isInviteOnly: PropTypes.bool,
  isTouch: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  applicantActiveAccountId: PropTypes.string,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};

CreateCalendarSlot.defaultProps = {
  activeAccountId: null,
  disabled: false,
  eventTypeId: null,
  isInviteOnly: false,
  isTouch: false,
  label: null,
  onChange: () => {},
  required: false,
  value: '',
  applicantActiveAccountId: null,
  isModalOpen: false,
  setIsModalOpen: () => {},
};

export default CreateCalendarSlot;
