import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import { fetchUtils, dateTimeUtils } from '../utils';
import SpanBtn from './SpanBtn';
import ToolTipContent from './ToolTipContent';

function CalendarSlot(props) {
  const { timeText, event: evObj = {}, isPast, isListView, onView, onEdit, onDelete, onCopy, isDisabled } = props;

  const { extendedProps = {}, title, start } = evObj;
  const { videoRoomId = '', bookedInCandidates = [], ics, isExternalEvent } = extendedProps;
  const isVideo = !!videoRoomId.length;
  const allowCandidateList = bookedInCandidates.length && bookedInCandidates.length <= 5;

  let inviteIdParam = '';
  if (isVideo && bookedInCandidates.length) {
    const [candidateBooking] = bookedInCandidates;
    if (candidateBooking?.inviteId) inviteIdParam = `&iid=${candidateBooking.inviteId}`;
  }

  return (
    <Tippy
      theme="ats"
      maxWidth="350px"
      interactive
      placement="left"
      disabled={isListView || isDisabled || (!videoRoomId.length && !allowCandidateList && !isExternalEvent)}
      content={
        isExternalEvent ? (
          <div>This is an synced calendar event and cannot be edited</div>
        ) : (
          <ToolTipContent
            videoRoomId={videoRoomId}
            bookedInCandidates={bookedInCandidates}
            inviteIdParam={inviteIdParam}
          />
        )
      }
    >
      <div className="fc-event-main-frame">
        <div
          className="fc-event-time"
          onClick={isExternalEvent || isDisabled ? undefined : onView}
          onKeyUp={isExternalEvent || isDisabled ? undefined : onView}
          role={isDisabled ? 'none' : 'button'}
          tabIndex="0"
        >
          {timeText}
        </div>
        <div
          className="fc-event-title-container"
          onClick={isExternalEvent || isDisabled ? undefined : onView}
          onKeyUp={isExternalEvent || isDisabled ? undefined : onView}
          role={isDisabled ? 'none' : 'button'}
          tabIndex="0"
        >
          <div className="fc-event-title fc-sticky">{title}</div>
        </div>
        {!(isPast || isDisabled || dateTimeUtils.isPast(start) || isExternalEvent) && (
          <div className={cx('fc-controls', { 'list-view': isListView })}>
            {isListView && isVideo && (
              <SpanBtn
                className="event-video"
                onClick={() => window.open(`/video/interview?id=${videoRoomId}${inviteIdParam}`)}
              >
                Video
              </SpanBtn>
            )}
            {ics && (
              <SpanBtn
                className="event-ics"
                onClick={async () => {
                  await fetchUtils.downloadIcs(ics, `${evObj.title} - ${evObj.start}`);
                }}
              >
                Download ICS
              </SpanBtn>
            )}
            <SpanBtn className="event-view" onClick={isExternalEvent ? undefined : onView}>
              View
            </SpanBtn>
            {checkPermissions(['scheduler:calendar:update']) && (
              <SpanBtn className="event-edit" onClick={isExternalEvent ? undefined : onEdit}>
                Edit
              </SpanBtn>
            )}
            {checkPermissions(['scheduler:calendar:update']) && (
              <SpanBtn className="event-copy" onClick={isExternalEvent ? undefined : onCopy}>
                Copy
              </SpanBtn>
            )}
            {checkPermissions(['scheduler:calendar:delete']) && (
              <SpanBtn className="event-delete" onClick={() => onDelete(bookedInCandidates)}>
                Delete
              </SpanBtn>
            )}
          </div>
        )}
      </div>
    </Tippy>
  );
}

CalendarSlot.propTypes = {
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  timeText: PropTypes.string,
  isPast: PropTypes.bool,
  isListView: PropTypes.bool,
  event: PropTypes.shape({
    title: PropTypes.string,
    extendedProps: PropTypes.shape({
      videoRoomId: PropTypes.string,
      bookedInCandidates: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }),
};

CalendarSlot.defaultProps = {
  onView: () => {},
  onEdit: () => {},
  onDelete: () => {},
  timeText: null,
  isPast: false,
  isListView: false,
  event: {},
};

export default CalendarSlot;
