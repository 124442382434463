import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import FunnelSelectInputs from '../../../../../../../Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import Select from 'react-select';
import {
  payrollStates,
  defaultStates,
  referenceStates,
  rtwStates,
  rtwOutcomes,
  personalityTestState,
  personalityTestScoreValues,
  personalityTestScoreTypes,
  eventStates,
  newCandidateFilterTypes,
} from '../../../../EventOptions';

import ApplicationQuestions from './ApplicationQuestions';
import AIDecisionCriteria from './AIDecisionCriteria';

const funnelStatusStates = [
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Hired', value: 'HIRED' },
];

function Conditional({ option, setOption }) {
  if (option?.value === 'applicationQuestion') {
    return <ApplicationQuestions option={option} setOption={setOption} />;
  } else if(option?.value === 'aiDecision') {
    return <AIDecisionCriteria option={option} setOption={setOption} />;
  }

  if (option?.value === 'funnelStatus') {
    return (
      <>
        <FormGroup>
          <Select
            className="react-select-lower"
            placeholder="Select Status"
            options={funnelStatusStates}
            value={funnelStatusStates.find((a) => a.value === option?.condition)}
            onChange={(val) => setOption(val.value)}
          />
        </FormGroup>
      </>
    );
  }

  if (
    option?.value === 'onboardingSummary.currentStatus.status' ||
    option?.value === 'edocSummary.currentStatus.status' ||
    option?.value === 'dbsCheckSummary.currentStatus.status' ||
    option?.value === 'genericFormSummary.currentStatus.status'
  ) {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select-lower"
          placeholder="Select Status"
          options={defaultStates}
          value={defaultStates.find((a) => a.value === option?.condition)}
          onChange={(val) => setOption(val.value)}
        />
      </FormGroup>
    );
  }

  if (option?.value === 'referenceSummary.currentStatus.status') {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select-lower"
          placeholder="Select Status"
          options={referenceStates}
          value={referenceStates.find((a) => a.value === option?.condition)}
          onChange={(val) => setOption(val.value)}
        />
      </FormGroup>
    );
  }

  if (option?.value === 'rtwSummary.currentStatus.status') {
    return (
      <div>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select-lower"
            placeholder="Select Status"
            options={rtwStates}
            value={rtwStates.find((a) => a.value === option?.condition)}
            onChange={(val) => setOption(val.value)}
          />
        </FormGroup>
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              Outcome<span className="required">*</span>
            </Label>
            <Select
              className="react-select-lower"
              placeholder="Select Outcome"
              options={rtwOutcomes}
              value={rtwOutcomes.find((a) => a.value === option?.outcome)}
              onChange={(val) => setOption(val.value)}
            />
          </FormGroup>
        )}
      </div>
    );
  }

  if (option?.value === 'personalityTestSummary.currentStatus.status') {
    return (
      <div>
        <FormGroup required>
          <Label>
            Status<span className="required">*</span>
          </Label>
          <Select
            className="react-select-lower"
            placeholder="Select Status"
            options={personalityTestState}
            value={personalityTestState.find((a) => a.value === option?.condition)}
            onChange={(val) => setOption(val.value)}
          />
        </FormGroup>
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              When Score is<span className="required">*</span>
            </Label>
            <Select
              className="react-select-lower"
              placeholder="Select Outcome"
              options={personalityTestScoreValues}
              value={personalityTestScoreValues.find((a) => a.value === option?.outcome)}
              onChange={(val) => setOption(val.value)}
            />
          </FormGroup>
        )}
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              Score Value<span className="required">*</span>
            </Label>
            <Input
              onChange={(e) => {
                setOption({ scoreValue: e.target.value });
              }}
              type="text"
              value={scoreValue}
            />
          </FormGroup>
        )}
        {option?.value === 'received' && (
          <FormGroup required>
            <Label>
              Score Type<span className="required">*</span>
            </Label>
            <Select
              className="react-select-lower"
              placeholder="Select Score type"
              options={personalityTestScoreTypes}
              value={personalityTestScoreTypes.find((a) => a.value === option?.scoreType)}
              onChange={(val) => setOption({ scoreType: val.value })}
            />
          </FormGroup>
        )}
      </div>
    );
  }

  if (option?.value === 'inviteSummary.currentStatus.status') {
    return (
      <FormGroup required>
        <Label>
          Status<span className="required">*</span>
        </Label>
        <Select
          className="react-select-lower"
          placeholder="Select Funnel Stage"
          options={eventStates}
          value={eventStates.find((a) => a.value === option?.condition)}
          onChange={(val) => setOption(val.value)}
        />
      </FormGroup>
    );
  }
}

Conditional.propTypes = {
  option: PropTypes.shape(),
};

Conditional.defaultProps = {
  isSliderOpen: {},
};

export default Conditional;
