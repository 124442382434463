import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserStore from 'store2';
import { toast } from 'react-toastify';
import { TopDetailBar } from '../../../Base/DetailBar';
import { FlexiForms } from '../FlexiForms';
import { EmailTemplates } from '../EmailTemplates';
import { TableSearchBar } from '../../../Base/Forms/Custom/TableSearchBar';
import { CreateButton, EditButton, TextButton } from '../../../Base/Buttons';
import { SidebarCol } from '../../../Base/Sidebar';
import { ScheduleAdmin } from '../Scheduler';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import { LoadingScreen } from '../../../Base/Loading';
import { localStorageNames } from '../../../../js/constants/storageNames';
import Can from '../../../Base/RBAC/Can/Can';
import ErrorMessage from '../../../Base/ErrorScreen/ErrorMessage';
import { BrandingForm } from '../Branding';
import VacancyTemplates from '../VacancyTemplates/VacancyTemplates';
import { PersonalInformation } from '../PersonalInformation';
import JobBoardSettings from '../JobBoards/JobBoardSettings';
import { AccountSelectorModal } from '../../../Base/Account';
import { LocationsAdmin } from '../Locations';
import { FourthAdmin } from '../Fourth';
import TagManager from '../Tags/TagManager';
import { S4Admin } from '../S4';
import { AccountAdmin, UserAdmin } from '../AccountManagement';
import { CompanyProfile } from '../CompanyProfile';
import Workflows from '../Workflow/Workflows';
import { AdvancedWorkflow } from '../AdvancedWorkflow';
import { ATR } from '../ATR';
import DashboardMenu from './DashboardMenu';
import { usePopstate } from '../../../Base/hooks';
import { isJSON } from '../../../../js/utils/general-utils';
import { FunnelAdmin } from '../Funnels';
import { PolarisAdmin } from '../Polaris';
import { HollwegAdmin } from '../Hollweg';
import { AccessAdmin } from '../Access';
import { RotaReadyAdmin } from '../RotaReady';
import { VacancySettings } from '../VacancySettings';
import SingleSignOn from '../AccountManagement/SingleSignOn';
import { reSyncAccounts } from '../../../../api/AccountAPI';
import { ModalPopup } from '../../../Base/Modal';
import { DbsCheckAdmin } from '../DbsCheck';
import { RejectionReasons } from '../RejectionReasons';

const RestrictedCreateButton = Can(CreateButton);
const RestrictedTextButton = Can(TextButton);
const RestrictedTableSearchBar = Can(TableSearchBar);
const RestrictedBrandingForm = Can(BrandingForm);
const RestrictedFlexiForms = Can(FlexiForms);
const RestrictedFunnels = Can(FunnelAdmin);
const RestrictedEmailTemplates = Can(EmailTemplates);
const RestrictedVacancyTemplates = Can(VacancyTemplates);
const RestrictedRejectionReasons = Can(RejectionReasons);
const RestrictedSchedule = Can(ScheduleAdmin);
const RestrictedTags = Can(TagManager);
const RestrictedWorkflows = Can(Workflows);
const RestrictedAdvancedWorkflows = Can(AdvancedWorkflow);
const RestrictedATR = Can(ATR);
const RestrictedAccountAdmin = Can(AccountAdmin);
const RestrictedUserAdmin = Can(UserAdmin);
const RestrictedFourth = Can(FourthAdmin);
const RestrictedS4 = Can(S4Admin);
const RestrictedPolaris = Can(PolarisAdmin);
const RestrictedHollweg = Can(HollwegAdmin);
const RestrictedAccess = Can(AccessAdmin);
const RestrictedRotaReady = Can(RotaReadyAdmin);
const RestrictedDbsCheck = Can(DbsCheckAdmin);
const RestrictedSSO = Can(SingleSignOn);

const { ADMIN_SECTION } = localStorageNames;

const titleLookup = {
  'personal-information': 'Personal Information',
  'job-boards': 'Job Boards',
  branding: 'Branding',
  'company-profile': 'Company Profile',
  vacancy: 'Vacancy Settings',
  forms: 'Forms',
  edocs: 'eDocs',
  funnels: 'Funnels',
  'email-templates': 'Email Templates',
  'vacancy-templates': 'Vacancy Templates',
  schedule: 'Schedule',
  locations: 'Locations',
  tags: 'Tags',
  workflows: 'Automated Workflows',
  RestrictedATR: 'Authorisation to Recruit',
  profile: 'Profile',
  accounts: 'Account Management',
  users: 'User Management',
  SSO: 'Single Sign On',
  fourth: 'Fourth Integration',
  polaris: 'Polaris Integration',
  hollweg: 'Hollweg Integration',
  atr: 'ATR',
  access: 'Access Integration',
  s4: 'S4 Integration',
  'same-system': 'Same System Integration',
  ubeya: 'Ubeya Integration',
};

const sectionLookup = {
  'personal-prefs': [
    { uri: 'job-boards', label: 'Job Board Settings' },
    { uri: 'personal-information', label: 'Personal Information' },
  ],
  'acc-settings': [
    { uri: 'atr', label: 'ATR', permissions: ['admin:atr:read'] },
    { uri: 'branding', label: 'Branding', permissions: ['admin:branding'] },
    { uri: 'company-profile', label: 'Company Profile' },
    { uri: 'edocs', label: 'eDocs', permissions: ['admin:edoc:read'] },
    { uri: 'email-templates', label: 'Email Templates', permissions: ['admin:emails:read'] },
    { uri: 'forms', label: 'Forms', permissions: ['admin:forms:read'] },
    { uri: 'funnels', label: 'Funnels', permissions: [] },
    { uri: 'locations', label: 'Locations', permissions: ['admin:locations:read'] },
    { uri: 'rejection-reasons', label: 'Rejection Reasons', permissions: ['admin:rejectionreasons:update'] },
    { uri: 'schedule', label: 'Schedule', permissions: ['admin:schedule:read'] },
    { uri: 'tags', label: 'Tags', permissions: ['candidate:tags'] },
    { uri: 'vacancy', label: 'Vacancy Settings', permissions: [] },
    { uri: 'vacancy-templates', label: 'Vacancy Templates', permissions: ['admin:vacancytemplates:read'] },
    { uri: 'workflows', label: 'Workflows', permissions: ['admin:workflow:read'] },
  ],
  'acc-management': [
    { uri: 'accounts', label: 'Accounts' },
    { uri: 'SSO', label: 'Single Sign On', permissions: ['admin:idp:create'] },
    { uri: 'users', label: 'Users' },
  ],
  integrations: [
    { uri: 'access', label: 'Access Group', permissions: ['admin:accessgrp'] },
    { uri: 'fourth', label: 'Fourth', permissions: ['fourth:admin'] },
    { uri: 'hollweg', label: 'Hollweg', permissions: ['pth:create'] },
    { uri: 'polaris', label: 'Polaris', permissions: ['polaris:admin'] },
    { uri: 'rota-ready', label: 'Rota Ready', permissions: ['admin:rota'] },
    { uri: 'dbs-check', label: 'DBS Check', permissions: ['dbs:admin'] },
    { uri: 's4', label: 'S4', permissions: ['s4:admin'] },
    { uri: 'ubyea', label: 'Ubeya', permissions: ['ubeya:admin'] },
  ],
};

const accountSwitchSections = ['schedule', 'email-templates', 'vacancy-templates', 'forms', 'locations', 'workflows'];

function FlexEnd({ children, className }) {
  return <div className={`d-flex justify-content-end${className ? ` ${className}` : ''}`}>{children}</div>;
}

FlexEnd.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

FlexEnd.defaultProps = {
  className: null,
};

function setLocalStore(sections, page) {
  const storeArr = sections.map((section) => {
    let pageStr = '';
    if (sectionLookup[section].find(({ uri }) => uri === page)) pageStr = page;
    return { section, page: pageStr };
  });

  browserStore(ADMIN_SECTION, JSON.stringify(storeArr));
}

function getLocalStore() {
  const storedVal = browserStore(ADMIN_SECTION);

  if (isJSON(storedVal)) {
    const storeArr = JSON.parse(browserStore(ADMIN_SECTION));

    if (Array.isArray(storeArr) && storeArr.length) {
      return storeArr.reduce(
        (acc, sectionObj) => ({
          ...acc,
          sections: [...acc.sections, sectionObj.section],
          ...(sectionObj.page ? { page: sectionObj.page } : {}),
        }),
        { sections: [], page: '' },
      );
    }
  }

  return { sections: [], page: 'personal-information' };
}

function ClientAdminDashboard({ activeAccountName, allowAccountSwitch, userType, email }) {
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState();
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [accountSwitchOpen, setAccountSwitchOpen] = useState(false);
  const [openSections, setOpenSections] = useState([]);
  const [isReSyncing, setIsResyncing] = useState(false);
  const pushHistory = usePopstate();
  const [titleSubItems, setTitleSubItems] = useState([]);

  useEffect(() => {
    const pName = pathname?.replace('/settings', '') || '';
    const storedState = getLocalStore();
    const { page } = storedState;
    let { sections } = storedState;

    const selectedTab = pName.length ? pName.slice(1) : page;
    setActiveTab(selectedTab);

    const parentSection = Object.entries(sectionLookup).reduce((acc, [section, items]) => {
      if (items.find(({ uri }) => uri === selectedTab)) return section;
      return acc;
    }, '');

    if (parentSection.length && !sections.includes(parentSection)) {
      sections = [...sections, parentSection];
    }

    sections = sections.filter((e) => e !== 'integrations');
    setOpenSections(sections);
  }, [pathname]);

  function handleSectionToggle(sectionName, isOpen) {
    let updatedSections = [...openSections];

    if (isOpen) {
      updatedSections = [...openSections, sectionName];
    } else {
      updatedSections = openSections.filter((section) => section !== sectionName);
    }

    setOpenSections(updatedSections);
    setLocalStore(updatedSections, activeTab);
  }

  function handleClick(page) {
    // if the tab changes we want to reset the header sub items
    // they will be set again once the subsection has loaded
    setTitleSubItems([]);

    pushHistory({ page }, `/settings/${page}`);
    setActiveTab(page);
    setLocalStore(openSections, page);
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }

  async function handleReSyncAccounts() {
    setIsResyncing(true);
    try {
      await reSyncAccounts(email);
      toast.success('Successfully reSynced accounts. Please refresh');
    } catch (e) {
      toast.error('Could not reSync accounts for your user');
    } finally {
      setIsResyncing(false);
    }
  }

  const pageTitle = titleLookup[activeTab] || 'Settings';

  return (
    <>
      <Row className="pb-0 mb-0">
        <Col>
          <TopDetailBar title={pageTitle} subItems={titleSubItems}>
            <div className="ms-auto">
              {activeTab === 'company-profile' && (
                <FlexEnd>
                  <EditButton action={() => setIsCreateMode(true)} floatRight={false} label="Edit Profile" />
                </FlexEnd>
              )}
              {activeTab === 'forms' && (
                <FlexEnd>
                  <RestrictedCreateButton
                    action={() => setIsImportModalOpen(true)}
                    className="me-2"
                    floatRight={false}
                    label="Import a new Form"
                    permissions={['talentfunnel:admin']}
                  />
                  <RestrictedCreateButton
                    action={() => setIsCreateMode(true)}
                    floatRight={false}
                    label="Create a new Form"
                    permissions={['admin:forms:create']}
                  />
                </FlexEnd>
              )}
              {activeTab === 'edocs' && (
                <FlexEnd>
                  <RestrictedCreateButton
                    action={() => setIsCreateMode(true)}
                    floatRight={false}
                    label="Create a new eDoc"
                    permissions={['admin:edoc:create']}
                  />
                </FlexEnd>
              )}
              {activeTab === 'funnels' && (
                <FlexEnd>
                  <RestrictedCreateButton
                    action={() => setIsCreateMode(true)}
                    floatRight={false}
                    label="Create a new Funnel"
                    permissions={['admin:funnel:create']}
                  />
                </FlexEnd>
              )}
              {/email-templates|vacancy-templates/.test(activeTab) && (
                <RestrictedTableSearchBar
                  minWidth="300px"
                  onSearch={(val) => setSearchTerm(val)}
                  permissions={['admin:emails:read']}
                  placeholder="Search Templates"
                >
                  <RestrictedCreateButton
                    action={() => setIsCreateMode(!isCreateMode)}
                    label={`Create ${activeTab === 'email-templates' ? 'Email' : 'Vacancy'} Template`}
                    permissions={activeTab === 'email-templates' ? ['admin:emails:create'] : []}
                  />
                </RestrictedTableSearchBar>
              )}
              {activeTab === 'locations' && (
                <FlexEnd>
                  <CreateButton action={() => setIsCreateMode(true)} floatRight={false} label="Create a new Location" />
                </FlexEnd>
              )}
              {activeTab === 'workflows' && (
                <FlexEnd>
                  <RestrictedCreateButton
                    action={() => setIsCreateMode(true)}
                    floatRight={false}
                    label="Create a new Workflow"
                    permissions={['admin:workflow:create']}
                  />
                </FlexEnd>
              )}
              {activeTab === 'accounts' && userType !== 'JOBSITE_ATS' && (
                <FlexEnd>
                  <RestrictedTextButton
                    action={() => handleReSyncAccounts()}
                    className="me-1"
                    disabled={isReSyncing}
                    floatRight={false}
                    label={isReSyncing ? 'ReSyncing...' : 'ReSync Accounts'}
                    permissions={['talentfunnel:admin']}
                  />
                  <RestrictedCreateButton
                    action={() => setIsCreateMode(true)}
                    floatRight={false}
                    label="Create a new Account"
                    permissions={['admin:usermanagement:create']}
                  />
                </FlexEnd>
              )}
              {activeTab === 'users' && (
                <FlexEnd>
                  <TableSearchBar
                    className="me-2"
                    minWidth="50%"
                    onSearch={(val) => setSearchTerm(val)}
                    placeholder="Search Users"
                  />
                  <RestrictedCreateButton
                    action={() => setIsCreateMode(true)}
                    floatRight={false}
                    label="Create a new User"
                    permissions={['admin:usermanagement:create']}
                  />
                </FlexEnd>
              )}
              {allowAccountSwitch && accountSwitchSections.includes(activeTab) && (
                <FlexEnd className="align-items-center mt-3">
                  <p>
                    {`Currently viewing ${pageTitle} in the ${activeAccountName} account `}
                    <a
                      href="#switch-account"
                      onClick={(e) => {
                        e.preventDefault();
                        setAccountSwitchOpen(true);
                      }}
                    >
                      Switch Account
                    </a>
                  </p>
                </FlexEnd>
              )}
            </div>
          </TopDetailBar>
        </Col>
      </Row>
      <Row className="flex-grow-1">
        <SidebarCol className="pt-3 bg-white ml-m-5" sm="2">
          <DashboardMenu
            iconType="Gear"
            activeItem={activeTab}
            id="personal-prefs"
            isOpen={openSections.includes('personal-prefs')}
            items={sectionLookup['personal-prefs']}
            onClick={handleClick}
            onToggle={handleSectionToggle}
            title="Personal Preferences"
          />
          {checkPermissions([
            'admin:forms:read',
            'admin:emails:read',
            'admin:schedule:read',
            'admin:vacancytemplates:read',
            'admin:rejectionreasons:update',
            'admin:branding',
            'admin:locations:read',
            'candidate:tags',
            'admin:workflow:read',
          ]) && (
            <DashboardMenu
              activeItem={activeTab}
              id="acc-settings"
              isOpen={openSections.includes('acc-settings')}
              items={sectionLookup['acc-settings']}
              onClick={handleClick}
              onToggle={handleSectionToggle}
              title="Account Settings"
              iconType="Briefcase"
            />
          )}
          {checkPermissions(['admin:usermanagement:read']) && (
            <DashboardMenu
              activeItem={activeTab}
              id="acc-management"
              isOpen={openSections.includes('acc-management')}
              items={
                userType === 'JOBSITE_ATS' ? [sectionLookup['acc-management'][1]] : sectionLookup['acc-management']
              }
              // eslint-disable-next-line max-len
              onClick={handleClick}
              onToggle={handleSectionToggle}
              title="Account Management"
              iconType="User"
            />
          )}
          {checkPermissions([
            'admin:accessgrp',
            'fourth:admin',
            's4:admin',
            'admin:rota',
            'polaris:admin',
            'same-system:admin',
            'ubeya:admin',
            'pth:create',
          ]) && (
            <DashboardMenu
              activeItem={activeTab}
              id="integrations"
              isOpen={openSections.includes('integrations')}
              items={sectionLookup.integrations}
              onClick={handleClick}
              onToggle={handleSectionToggle}
              title="Integrations"
              iconType="Link"
            />
          )}
        </SidebarCol>
        {activeTab ? (
          <>
            {activeTab === 'personal-information' && <PersonalInformation />}
            {activeTab === 'job-boards' && <JobBoardSettings />}
            {activeTab === 'company-profile' && (
              <CompanyProfile onClose={() => setIsCreateMode(false)} openSlider={isCreateMode} />
            )}
            {activeTab === 'branding' && <RestrictedBrandingForm permissions={['admin:branding']} />}
            {activeTab === 'forms' && (
              <RestrictedFlexiForms
                canCreateForm={checkPermissions(['admin:forms:create'])}
                deniedDisplay={<ErrorMessage error={403} />}
                initialData={initialData}
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:forms:read']}
                restrictedToFormTypes={[
                  'APPLICATION_FORM',
                  'ONBOARDING',
                  'INTERVIEW',
                  'REFERENCE',
                  'GENERAL',
                  'REFEREE',
                  'GENERIC',
                ]}
              />
            )}
            {activeTab === 'edocs' && (
              <RestrictedFlexiForms
                deniedDisplay={<ErrorMessage error={403} />}
                editorType="EDOC"
                formTypeTitleName="eDoc"
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:edoc:read']}
                restrictedToFormTypes={['EDOC']}
              />
            )}
            {activeTab === 'funnels' && (
              <RestrictedFunnels isCreateMode={isCreateMode} onClose={() => setIsCreateMode(false)} permissions={[]} />
            )}
            {activeTab === 'email-templates' && (
              <RestrictedEmailTemplates
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:emails:read']}
                searchTerm={searchTerm}
                updateCreateMode={(val) => setIsCreateMode(val)}
              />
            )}
            {activeTab === 'vacancy-templates' && (
              <RestrictedVacancyTemplates
                isCreateMode={isCreateMode}
                // searchTerm={searchTerm}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:vacancytemplates:read']}
              />
            )}
            {activeTab === 'vacancy' && (
              <VacancySettings
                // searchTerm={searchTerm}
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
              />
            )}
            {activeTab === 'rejection-reasons' && (
              <RestrictedRejectionReasons
                // searchTerm={searchTerm}
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:rejectionreasons:update']}
              />
            )}
            {activeTab === 'schedule' && <RestrictedSchedule permissions={['admin:schedule:read']} />}
            {activeTab === 'locations' && checkPermissions(['admin:locations:read']) && (
              <LocationsAdmin
                // permissions={['admin:locations:read']}
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
              />
            )}
            {activeTab === 'tags' && <RestrictedTags permissions={['candidate:tags']} />}
            {activeTab === 'workflows' && (
              <RestrictedWorkflows
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:workflow:read']}
              />
            )}
            {activeTab === 'advanced-workflows' && (
              <RestrictedAdvancedWorkflows permissions={['admin:workflow:read']} />
            )}
            {activeTab === 'atr' && (
              <RestrictedATR
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:atr:read']}
              />
            )}
            {activeTab === 'accounts' && userType !== 'JOBSITE_ATS' && (
              <RestrictedAccountAdmin
                isCreateMode={isCreateMode}
                // searchTerm={searchTerm}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:usermanagement:read']}
                setTitleSubItems={setTitleSubItems}
              />
            )}
            {activeTab === 'users' && (
              <RestrictedUserAdmin
                isCreateMode={isCreateMode}
                onClose={() => setIsCreateMode(false)}
                permissions={['admin:usermanagement:read']}
                searchTerm={searchTerm}
                setTitleSubItems={setTitleSubItems}
              />
            )}
            {activeTab === 'SSO' && <RestrictedSSO permissions={[]} />}
            {activeTab === 'fourth' && <RestrictedFourth permissions={['fourth:admin']} />}
            {activeTab === 'polaris' && <RestrictedPolaris permissions={['polaris:admin']} />}
            {activeTab === 'hollweg' && (
              <RestrictedHollweg
                deniedDisplay={
                  <Col className="mt-2">
                    <ErrorMessage error={403} />
                  </Col>
                }
                permissions={['pth:create']}
              />
            )}
            {activeTab === 'access' && (
              <RestrictedAccess
                deniedDisplay={
                  <Col className="mt-2">
                    <ErrorMessage error={403} />
                  </Col>
                }
                permissions={['admin:accessgrp']}
              />
            )}
            {activeTab === 's4' && <RestrictedS4 permissions={['s4:admin']} />}
            {activeTab === 'rota-ready' && (
              <RestrictedRotaReady
                deniedDisplay={
                  <Col className="mt-2">
                    <ErrorMessage error={403} />
                  </Col>
                }
                permissions={['admin:rota']}
              />
            )}
            {activeTab === 'dbs-check' && (
              <RestrictedDbsCheck
                deniedDisplay={
                  <Col className="mt-2">
                    <ErrorMessage error={403} />
                  </Col>
                }
                permissions={['dbs:admin']}
              />
            )}
          </>
        ) : (
          <Col>
            <LoadingScreen isEmbeded />
          </Col>
        )}
      </Row>
      <AccountSelectorModal isOpen={accountSwitchOpen} onToggle={() => setAccountSwitchOpen(false)} />
      <ModalPopup
        className="m-auto"
        disableOkayButton={!initialData}
        isOpen={isImportModalOpen}
        onCancel={() => {
          setInitialData(null);
        }}
        onOkay={() => {
          setIsCreateMode(true);
        }}
        onToggle={(isOpen) => {
          setIsImportModalOpen(isOpen);
          setUploadError(null);
        }}
        title="Import a form"
      >
        <Input
          accept=".json"
          className="m-auto h-100 mt-4"
          onChange={(event) => {
            setUploadError(null);
            if (event.target.files[0]) {
              // Read and parse the JSON file
              const fileReader = new FileReader();
              fileReader.readAsText(event.target.files[0], 'UTF-8');
              fileReader.onload = (e) => {
                try {
                  const json = JSON.parse(e.target.result);

                  const hasRequiredFields =
                    json.formSchema && json.name && json.rules && json.sharedWith && json.type && json.uiSchema;

                  if (!hasRequiredFields) {
                    setUploadError(
                      `Please upload a valid form file. It should contain: formSchema, name, rules, sharedWith, type, uiSchema.`,
                    );
                  } else {
                    setInitialData(json);
                  }
                } catch (error) {
                  console.error('Error parsing JSON:', error);
                }
              };
            }
          }}
          type="file"
        />
        {uploadError ? <h5 className="text-danger mt-4">{uploadError}</h5> : null}
      </ModalPopup>
    </>
  );
}

ClientAdminDashboard.propTypes = {
  activeAccountName: PropTypes.string,
  allowAccountSwitch: PropTypes.bool,
  email: PropTypes.string,
  location: PropTypes.shape(),
  userType: PropTypes.string,
};

ClientAdminDashboard.defaultProps = {
  activeAccountName: null,
  allowAccountSwitch: false,
  email: null,
  location: {},
  userType: null,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        activeAccountName,
        data: { accountAccess = [], userType },
        email,
      },
    },
  } = state;

  return {
    activeAccountName,
    allowAccountSwitch: accountAccess.length > 1,
    email,
    userType,
  };
}

export default connect(mapStateToProps)(ClientAdminDashboard);
