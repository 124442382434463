import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { TextButton, EditButton, ActionButton } from '../../Base/Buttons';
import Can from '../../Base/RBAC/Can/Can';
import { MappedAnswers } from '../Candidates/CandidateDetails';
import { FormModal } from '../../Base/Modal';
import config from '../../../config/config';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getFlexiFormViewIdsByType } from '../../../api/FormsAPI/ATSFormsAPI';
import { useMounted } from '../../Base/hooks';
import { HeadingPushRight } from '../../Base/Headings';
import { generateToken } from './utils';
import CopyToClipboardButton from '../../Base/Buttons/CopyToClipboardButton';

const {
  web: { formsWebUrl },
} = config;
const RestrictedTextButton = Can(TextButton);
const RestrictedEditButton = Can(EditButton);
const RestrictedCopyToClipboardButton = Can(CopyToClipboardButton);

async function fetchFormId(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getFlexiFormViewIdsByType('REFERENCE'));

  if (typeof resp === 'string') {
    toast.error('Error fetching referee form');
  } else {
    const [id] = Object.keys(resp);
    if (id) {
      onSuccess(id);
    } else {
      toast.error('Error fetching referee form');
    }
  }
}

function Referees({
  referees,
  languagePack,
  onReminder,
  candidate,
  userDetails,
  refRequestStatus,
  requestReferences,
  doRefresh,
  isTouch,
}) {
  const isMounted = useMounted();
  const [btnLoading, setBtnLoading] = useState(false);
  const [formId, setFormId] = useState();
  const [modalConfig, setModalConfig] = useState({ isOpen: false, url: '' });
  const [refereeActionBtnIsOpen, setRefereeActionBtnIsOpen] = useState({ isOpen: false, id: null, url: '' });
  const [refBtnLoading, setRefBtnLoading] = useState({ isOpen: false, url: '' });

  useEffect(() => {
    if (isMounted()) fetchFormId((id) => setFormId(id));
  }, [isMounted]);

  const DetailRow = ({ title, value }) => (
    <Row>
      <Col>
        <p className="detail-title font-weight-bold">{title}</p>
      </Col>
      <Col>
        <p className="detail-value">{value}</p>
      </Col>
    </Row>
  );

  return (
    <>
      {referees.map((referee) => {
        const {
          id,
          firstName,
          surname,
          refereeRequestStatus,
          email,
          phone,
          answers,
          attachments,
          completedFormId,
          relationToApplicant,
          company,
          position,
        } = referee;
        const btnLabel = btnLoading
          ? languagePack.reminderSendingBtn
          : languagePack[refereeRequestStatus === 'REQUESTED' ? 'sendReminder1Btn' : 'sendReminder2Btn'];

        let updatedDateTime;

        if (referee.statusUpdatedDateTime) {
          updatedDateTime = `${languagePack.on} ${moment
            .utc(referee.statusUpdatedDateTime)
            .local()
            .format('DD-MM-YYYY HH:mm')}`;
        } else {
          updatedDateTime = '';
        }

        const referenceFormExpirationDate = moment(referee.statusUpdatedDateTime).add(1, 'year');
        const isReferenceFormOutdated = moment().isAfter(referenceFormExpirationDate);
        const referenceFormURL = `${formsWebUrl}?id=${formId}&cid=${candidate.id}&rid=${id}&userid=${userDetails.id}&username=${userDetails.firstName}%20${userDetails.lastName}`;

        const menuItems = [
          {
            icon: 'Pencil',
            action: async () => {
              {
                setModalConfig({
                  isOpen: true,
                  url: referenceFormURL,
                });
                setRefereeActionBtnIsOpen({ isOpen: false, id: null, url: '' });
              }
            },
            id: 1,
            isHidden: /^RECEIVED/.test(refereeRequestStatus) && completedFormId,
            isDisabled: false,
            label: languagePack.completeReferenceInfoBtn,
            permissions: ['candidate:references:create'],
          },
          {
            action: async () => {
              setBtnLoading(true);
              onReminder(id, () => setBtnLoading(false));
              setRefereeActionBtnIsOpen({ isOpen: false, id: null, url: '' });
            },
            icon: 'File',
            isHidden:
              (/^RECEIVED/.test(refereeRequestStatus) && completedFormId) ||
              /^REMINDER_2_SENT/.test(refereeRequestStatus),
            id: 2,
            label: btnLabel,
            permissions: ['candidate:references:create'],
          },
          {
            action: async () => {
              navigator.clipboard.writeText(referenceFormURL).then(
                () => {
                  toast.success(`Reference Form URL ${languagePack.copiedToClipboard}`);
                },
                () => {
                  toast.error(languagePack.copyToClipboardError);
                },
              );
              setRefereeActionBtnIsOpen({ isOpen: false, id: null, url: '' });
            },
            icon: 'Pencil',
            id: 3,
            isHidden: !/^REQUESTED|REMINDER_1_SENT|REMINDER_2_SENT/.test(refereeRequestStatus),
            isDisabled: false,
            label: languagePack.copyReferenceUrlBtnText,
            permissions: ['candidate:references:create'],
          },
          {
            action: async () => {
              generateToken(completedFormId, (token) => {
                setModalConfig({
                  isOpen: true,
                  url: `${formsWebUrl}?token=${token}`,
                });
                setRefereeActionBtnIsOpen({ isOpen: false, id: null, url: '' });
              });
            },
            icon: 'File',
            id: 4,
            isHidden: !(/^RECEIVED/.test(refereeRequestStatus) && completedFormId),
            isDisabled: isReferenceFormOutdated,
            label: languagePack.editReferenceInfoBtn,
            permissions: ['candidate:references:create'],
            tooltipContent: 'You are unable to edit records that are over 12 months old',
          },
        ];

        return (
          <Fragment key={id}>
            <hr />
            <HeadingPushRight
              headingAs="h5"
              headingClassName="text-capitalize smaller"
              headingText={`${firstName} ${surname} - ${
                languagePack[refereeRequestStatus] || refereeRequestStatus
              } ${updatedDateTime}`}
              isTouch={isTouch}
            >
              <ActionButton
                buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                className="ellipsis-opts-btn"
                iconName="Ellipsis"
                iconOnly={false}
                isOpen={refereeActionBtnIsOpen.isOpen && refereeActionBtnIsOpen.id === referee.id} // Check if this referee's button is open
                menuItems={menuItems}
                onToggle={(isOpen) => {
                  setRefereeActionBtnIsOpen(
                    isOpen ? { isOpen: true, id: referee.id, url: '' } : { isOpen: false, id: null, url: '' },
                  ); // Adjust the toggle function to set the current referee id
                }}
                title="Actions"
                tooltipContent={languagePack.tooltipContent || 'Perform actions'}
              />
              {/^NOT_REQUESTED/.test(refereeRequestStatus) && !/^NOT_REQUESTED/.test(refRequestStatus) && (
                <div>
                  <RestrictedTextButton
                    action={() => requestReferences([referee.email])}
                    className="float-end"
                    label={languagePack.requestReferenceSingleBtn}
                    permissions={['candidate:references:create']}
                  />
                </div>
              )}
            </HeadingPushRight>
            {refereeRequestStatus === 'RECEIVED' ? (
              <MappedAnswers
                answers={answers}
                attachments={attachments}
                candidateId={candidate.id}
                headingAs="h5"
                titleWidth={5}
              />
            ) : (
              <Row>
                <Col>
                  {/^NOT_REQUESTED|REQUESTED|PART_RECEIVED|REMINDER_1_SENT|REMINDER_2_SENT/.test(
                    refereeRequestStatus,
                  ) &&
                  phone &&
                  email ? (
                    <div className="mt-2 mb-2">
                      <DetailRow title={languagePack.firstName} value={firstName} />
                      <DetailRow title={languagePack.surname} value={surname} />
                      <DetailRow title={languagePack.company} value={company} />
                      <DetailRow title={languagePack.position} value={position} />
                      <DetailRow title={languagePack.relationToApplicant} value={relationToApplicant} />
                      <DetailRow title={languagePack.emailAddress} value={email} />
                      <DetailRow title={languagePack.phoneNumber} value={`+${phone}`} />
                    </div>
                  ) : null}
                </Col>
              </Row>
            )}
          </Fragment>
        );
      })}
      <FormModal
        contentUrl={modalConfig.url}
        id="referee-details"
        isOpen={modalConfig.isOpen}
        toggle={(closeMethod, isOpen, frameId) => {
          if (closeMethod === 'IFRAME' && isOpen && frameId === 'referee-details') {
            doRefresh();
          }
          setModalConfig({ isOpen: false, url: '' });
        }}
        warnOnClickClose
      />
    </>
  );
}

Referees.propTypes = {
  candidate: PropTypes.shape(),
  doRefresh: PropTypes.func,
  isTouch: PropTypes.bool,
  languagePack: PropTypes.shape(),
  onReminder: PropTypes.func,
  refRequestStatus: PropTypes.string,
  referees: PropTypes.arrayOf(PropTypes.shape()),
  requestReferences: PropTypes.func,
  userDetails: PropTypes.shape(),
};

Referees.defaultProps = {
  candidate: {},
  doRefresh: () => {},
  isTouch: false,
  languagePack: {},
  onReminder: () => {},
  refRequestStatus: {},
  referees: [],
  requestReferences: () => {},
  userDetails: {},
};

export default Referees;
