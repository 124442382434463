import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { api } = config;

export async function synthesizeSpeech(text) {
  const result = await axios.post(
    `${api.middlewareAPIURL}/ai/tts`,
    text,
    {
      headers: {
        ...getCommonHeaders(),
      },
      responseType: 'blob'
    }
  );

  if (result.status === 200) return result.data;
  return null;
}
