import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment';
import { dateTimeUtils } from '../utils';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import CalendarSlot from './CalendarSlot';
import useDeviceDetection from '../../../js/utils/useDeviceDetection';

const viewLookup = {
  default: {
    dayGridMonth: 'listMonth',
    timeGridWeek: 'listWeek',
    timeGridDay: 'listDay',
    timeGridFiveDay: 'listFiveDay',
  },
  list: {
    listMonth: 'dayGridMonth',
    listWeek: 'timeGridWeek',
    listDay: 'timeGridDay',
    listFiveDay: 'timeGridFiveDay',
  },
};

function Calendar({
  events,
  activeAccountId,
  onEventCreate,
  onEventCopy,
  onEventEdit,
  onRender,
  onEventDelete,
  onViewToggle,
}) {
  const calRef = useRef(null);
  const wrapperRef = useRef();
  const [isListView, setIsListView] = useState(false);
  const [calendarView, setCalendarView] = useState('timeGridWeek');
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [calKey, setCalKey] = useState('');
  const device = useDeviceDetection();
  const isMobile = device === 'Mobile';

  useEffect(() => {
    // when accountId changes force calendar refresh using key prop as activeAccountId
    // https://github.com/fullcalendar/fullcalendar-react/issues/57
    setCalKey((prevState) => (prevState !== activeAccountId ? activeAccountId : prevState));
  }, [activeAccountId]);

  useEffect(() => {
    if (wrapperRef.current) {
      // 50 is height of footer
      // 25 is padding and margins
      const viewHeight = document.documentElement.scrollHeight - 75;
      const { top } = wrapperRef.current.getBoundingClientRect();
      const availableSpace = viewHeight - Math.ceil(top);
      setWrapperHeight(availableSpace);
    }
  }, []);

  let viewButtons = 'dayGridMonth,timeGridWeek,timeGridDay,timeGridFiveDay';
  if (isListView) viewButtons = 'listMonth,listWeek,listDay,listFiveDay';

  let initialView = 'timeGridWeek';

  if (isMobile) {
    viewButtons = '';
    initialView = 'timeGridDay';
  }

  function handleEventHover(eventInfo, hover) {
    const { el } = eventInfo;
    el.classList[hover ? 'add' : 'remove']('event-hover');
  }

  // find the current time then move the calendar to that time
  const currentTime = new Date();
  const formattedTime = `${currentTime.getHours()}:${currentTime.getMinutes()}}`;

  return (
    <div id="schedule-calendar" className="mt-4" style={{ height: `${wrapperHeight}px` }} ref={wrapperRef} key={calKey}>
      <FullCalendar
        ref={calRef}
        events={events}
        // timeZone={dateTimeUtils.getTimeZone()}
        initialView={initialView}
        firstDay={1}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, listPlugin]}
        themeSystem="bootstrap5"
        height="100%"
        slotDuration="00:15:00"
        slotMinTime="08:00:00"
        slotMaxTime="21:00:00"
        views={{
          timeGrid: {
            dayHeaderContent: ({ date }) => moment(date).format('ddd D/M'),
          },
          timeGridFiveDay: {
            type: 'timeGrid',
            duration: { days: 5 },
            buttonText: '5 Day',
          },
          listFiveDay: {
            type: 'list',
            duration: { days: 5 },
            buttonText: '5 Day',
          },
        }}
        customButtons={{
          viewToggle: {
            text: isListView ? 'Calendar' : 'List',
            id: 'list-btn',
            click: () => {
              if (calRef.current) {
                const calendarApi = calRef.current.getApi();
                const viewType = calendarApi.view.type;
                const lookup = isListView ? viewLookup.list : viewLookup.default;
                if (lookup[viewType]) {
                  calendarApi.changeView(lookup[viewType]);
                  setIsListView(!isListView);
                  onViewToggle(!isListView);
                }
              }
            },
          },
        }}
        editable
        selectable
        selectMirror
        scrollTime={formattedTime}
        longPressDelay={500}
        select={(dateInfo) => onEventCreate(dateInfo)}
        // allow future events only to be created
        selectAllow={(dateInfo) => {
          if (checkPermissions(['scheduler:calendar:create'])) {
            return calendarView === 'dayGridMonth'
              ? !moment(dateInfo.start).isBefore(new Date(), 'day')
              : dateTimeUtils.isFuture(dateInfo.start);
          }
          return false;
        }}
        // eventClick={(dateInfo) => onEventEdit(dateInfo.event)}
        eventMouseEnter={(evt) => handleEventHover(evt, true)}
        eventMouseLeave={(evt) => handleEventHover(evt, false)}
        eventContent={(eventInfo) => (
          <CalendarSlot
            {...eventInfo}
            isListView={isListView}
            onView={() => onEventEdit(eventInfo.event)}
            onEdit={() => onEventEdit(eventInfo.event, true)}
            onDelete={(bookedInCandidates) => onEventDelete(eventInfo?.event?.id, bookedInCandidates)}
            onCopy={() => onEventCopy(eventInfo.event)}
          />
        )}
        datesSet={({ view }) => {
          const { type, title, activeStart, activeEnd } = view;

          setCalendarView(type);

          onRender({
            type,
            title,
            activeStart,
            activeEnd,
          });
        }}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
        }}
        headerToolbar={{
          left: 'prev,next today viewToggle',
          center: 'title',
          right: viewButtons,
        }}
        buttonText={{
          today: 'Today',
          month: 'Month',
          week: 'Week',
          day: 'Day',
        }}
      />
    </div>
  );
}

Calendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      allDay: PropTypes.bool,
      title: PropTypes.string,
      extendedProps: PropTypes.shape(),
    }),
  ),
  activeAccountId: PropTypes.string,
  onEventCreate: PropTypes.func,
  onEventEdit: PropTypes.func,
  onRender: PropTypes.func,
  onEventDelete: PropTypes.func,
  onViewToggle: PropTypes.func,
  onEventCopy: PropTypes.func,
};

Calendar.defaultProps = {
  events: [],
  activeAccountId: '',
  onEventCreate: () => {},
  onEventEdit: () => {},
  onRender: () => {},
  onEventDelete: () => {},
  onViewToggle: () => {},
  onEventCopy: () => {},
};

export default Calendar;
