import React from 'react';
import PropTypes from 'prop-types';
import EventInput from './EventInput';
import moment from 'moment';

function EventInputs({
  configs,
  isEditable,
  isEditing,
  onChange,
  formData,
  eventFormat,
  errors,
  availableSlots,
  onOptionLoad,
  activeAccountId,
  isTouch,
  noTemplates,
}) {
  return configs.map((inputObj) => {
    const {
      id,
      type,
      label,
      required,
      dynamicOptions,
      displayIfLT2,
      isMulti,
      min,
      showIfRecurring,
      hideIfRecurring,
      disableOnEdit,
      ignoreDisplayMode,
      hideOnRead,
      inline,
      className,
    } = inputObj;
    let { max, helpText, options } = inputObj;

    const isRecurring = /DAILY|WEEKLY/.test(formData.recurrenceType);

    if (!isRecurring && showIfRecurring) return null;
    if (isRecurring && hideIfRecurring) return null;

    if (id === 'slotDuration' && formData.endDate && formData.startDate) {
      const startDate = moment(formData.startDate);
      const endDate = moment(formData.endDate);
      const diff = endDate.diff(startDate, 'minutes');

      // Initialize an empty array for filtered options
      let filteredOptions = [{ value: 'FULL', label: 'N/A' }];

      // Check the time difference and progressively prepend options
      if (diff % 15 === 0) {
        filteredOptions.push({ value: '15', label: '15 Mins' });
      }
      if (diff % 30 === 0) {
        filteredOptions.push({ value: '30', label: '30 Mins' });
      }
      if (diff % 45 === 0) {
        filteredOptions.push({ value: '45', label: '45 Mins' });
      }
      if (diff % 60 === 0) {
        filteredOptions.push({ value: '60', label: '1 Hour' });
      }

      // Assign the filtered options to the `options` variable
      options = filteredOptions;
    }

    const invalid = id in errors && errors[id].invalid;
    const errorMsg = invalid ? errors[id].errors[0] : '';

    let displayMode = 'create';
    if (isEditable) displayMode = isEditing ? 'update' : 'read';

    if (ignoreDisplayMode) {
      if (displayMode === 'read') {
        displayMode = 'update';
      } else if (displayMode === 'update') {
        displayMode = 'read';
      }
    }

    if (hideOnRead && displayMode === 'read') return null;

    let isDisabled = disableOnEdit && isEditable;

    if (id === 'numSpaces' && type === 'number' && eventFormat === 'VIDEO') {
      max = 1;
      helpText = 'Limit of 1 space for Video Interview';
      isDisabled = true;
    }

    return (
      <EventInput
        key={id}
        activeAccountId={activeAccountId}
        availableSlots={availableSlots}
        className={className}
        disabled={isDisabled}
        displayIfLT2={displayIfLT2}
        displayMode={displayMode}
        errorMessage={errorMsg}
        eventTypeId={formData.eventTypeId}
        helpText={helpText}
        id={id}
        inline={inline}
        invalid={invalid}
        inviteType={formData.inviteType}
        isAsync={dynamicOptions}
        isMulti={isMulti}
        isTouch={isTouch}
        label={label}
        max={max}
        min={min}
        onChange={(val, valObj) => onChange(id, val, inputObj, valObj)}
        // valObj returned from SelectInput only
        onOptionLoad={onOptionLoad}
        options={options}
        required={required}
        type={type}
        value={formData[id]}
        noTemplates={noTemplates}
        formData={formData}
      />
    );
  });
}

EventInputs.propTypes = {
  activeAccountId: PropTypes.string,
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      availableSlots: PropTypes.number,
      dynamicOptions: PropTypes.bool,
      helpText: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      options: PropTypes.arrayOf(PropTypes.shape()),
      required: PropTypes.bool,
      showIfRecurring: PropTypes.bool,
      type: PropTypes.string,
    }),
  ),
  errors: PropTypes.shape(),
  eventFormat: PropTypes.string,
  formData: PropTypes.shape(),
  isEditable: PropTypes.bool,
  isEditing: PropTypes.bool,
  isTouch: PropTypes.bool,
  onChange: PropTypes.func,
  onOptionLoad: PropTypes.func,
  noTemplates: PropTypes.bool,
};

EventInputs.defaultProps = {
  activeAccountId: null,
  availableSlots: undefined,
  configs: [],
  errors: {},
  eventFormat: null,
  formData: {},
  isEditable: false,
  isEditing: false,
  isTouch: false,
  onChange: () => {},
  onOptionLoad: () => {},
  noTemplates: false,
};

export default EventInputs;
