import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EmailForm } from '../../Communication';

function ConfirmSlotDelete({
  onChange,
  confirmSubmit,
  invalidEmail,
  companyName,
  contactEmail,
}) {
  const [emailData, setEmailData] = useState({
    fromName: companyName,
    replyTo: contactEmail,
  });

  return (
    <Fragment>
      <p className="mb-2">
        Notify the attendees that the event has been cancelled. You can edit the email if you wish to add more detail.
      </p>
      <div
        className="invalid-feedback"
        style={{ display: confirmSubmit && invalidEmail ? 'block' : 'none' }}
      >
        Email form has missing required fields
      </div>
      <EmailForm
        data={emailData}
        externalSend
        externalSubmitted={confirmSubmit}
        applicants={[]}
        removeInputs={['to', 'ccAddresses', 'bccAddresses', 'attachments']}
        actionType="EVENT_CANCELLED"
        onChange={(formData, validity) => {
          const {
            fromName,
            replyTo,
            subject,
            message,
          } = formData;

          setEmailData({
            fromName,
            replyTo,
            subject,
            message,
          });

          onChange({
            fromName,
            replyTo,
            subject,
            htmlContent: message,
          }, validity.isValid);
        }}
      />
    </Fragment>
  );
}

ConfirmSlotDelete.propTypes = {
  onChange: PropTypes.func,
  confirmSubmit: PropTypes.bool,
  invalidEmail: PropTypes.bool,
  companyName: PropTypes.string,
  contactEmail: PropTypes.string,
};

ConfirmSlotDelete.defaultProps = {
  onChange: () => { },
  invalidEmail: false,
  confirmSubmit: false,
  companyName: '',
  contactEmail: '',
};

function mapStateToProps(state) {
  const { companyData: { companyDetails: { companyName, contactEmail } } } = state;
  return { companyName, contactEmail };
}

export default connect(mapStateToProps)(ConfirmSlotDelete);
