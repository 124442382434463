export default {
  SCHEDULED: {
    text: 'Scheduled',
    color: 'info',
  },
  CLOSED: {
    text: 'Closed',
    color: 'danger',
  },
  EXPIRED: {
    text: 'Expired',
    color: 'danger',
  },
  EXPIRES_TODAY: {
    text: 'Expires Today',
    color: 'warning',
  },
  AWAITING_AUTHORISATION: {
    text: 'Awaiting Authorisation',
    color: 'warning',
  },
  AUTHORISATION_REJECTED: {
    text: 'Authorisation Rejected',
    color: 'danger',
  },
  PAUSED: {
    text: 'Paused',
    color: 'warning',
  },
  LIVE: {
    text: 'Live',
    color: 'success',
  },
  ADDITIONAL_INFO_REQUESTED: {
    color: 'warning',
    text: 'Referees Requested',
  },
  NOT_REQUESTED: {
    color: 'secondary',
    text: 'Not Requested',
  },
  REQUESTED: {
    color: 'warning',
    text: 'Requested',
  },
  SUBMITTED: {
    color: 'secondary',
    text: 'Submitted',
  },
  REMINDER_1_SENT: {
    color: 'warning',
    text: 'Reminder 1 sent',
  },
  REMINDER_2_SENT: {
    color: 'warning',
    text: 'Reminder 2 sent',
  },
  REMINDERS_SENT: {
    color: 'warning',
    text: 'Reminders sent',
  },
  PART_RECEIVED: {
    color: 'warning',
    text: 'Part received',
  },
  RECEIVED: {
    color: 'success',
    text: 'Received',
  },
  INVITED: {
    color: 'warning',
    text: 'Invited',
  },
  ACCEPTED: {
    color: 'success',
    text: 'Accepted',
  },
  ATTENDED: {
    color: 'success',
    text: 'Attended',
  },
  DECLINED: {
    color: 'danger',
    text: 'Declined',
  },
  NO_SHOW: {
    color: 'danger',
    text: 'No show',
  },
  WITHDRAWN: {
    color: 'danger',
    text: 'Withdrawn',
  },
  MULTI: {
    color: 'warning',
    text: 'Multi',
  },
  FAILED: {
    color: 'danger',
    text: 'FAILED',
  },
};
