import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { api, scheduling } = config;

export async function createSlot(slotData = {}, accountId) {
  if (!Object.keys(slotData).length) return null;

  const result = await axios.post(
    `${api.middlewareAPIURL}${scheduling.slotPath}`,
    { ...slotData },
    { headers: getCommonHeaders(accountId) },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function deleteSlot(id, emailData = {}, isRecurrence = false) {
  if (id) {
    const url = isRecurrence ? '/recurring' : '';

    const result = await axios.delete(`${api.middlewareAPIURL}${scheduling.slotPath}${url}`, {
      data: {
        id,
        ...(emailData && Object.keys(emailData).length ? { emailDetails: { ...emailData } } : {}),
      },
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateSlot(slotData = {}) {
  if (!Object.keys(slotData).length) return null;

  const result = await axios.put(
    `${api.middlewareAPIURL}${scheduling.slotPath}`,
    { ...slotData },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function syncCalendar(type) {
  const result = await axios.get(`${api.middlewareAPIURL}${scheduling.getSyncedCalendarsPath}/${type.toUpperCase()}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function syncCalendarCallback(code, accountIds, colour, type, syncType, obfuscatedSync, onlyPersonal) {
  let uri = `${api.middlewareAPIURL}${scheduling.syncCalendarGoogleCallbackPath}/${type.toUpperCase()}?code=${code}&accountIds=${accountIds.join(',')}&syncType=${syncType}&obfuscatedSync=${obfuscatedSync}&onlyPersonal=${onlyPersonal}`;

  if (colour) {
    uri += `&colour=${colour}`;
  }

  const result = await axios.get(uri, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function revokeCalendarAccess(type) {
  const result = await axios.delete(
    `${api.middlewareAPIURL}${scheduling.getSyncedCalendarsPath}/${type.toUpperCase()}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getSyncedCalendars() {
  const result = await axios.get(`${api.middlewareAPIURL}${scheduling.getSyncedCalendarsPath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getEvents(startDate, endDate, accountId, eventTypeId, isInviteOnly, onlyAvailable) {
  if (!startDate || !endDate) return null;

  let url = `${api.middlewareAPIURL}${scheduling.events}?startDate=${startDate}&endDate=${endDate}`;
  if (accountId && typeof accountId === 'string') {
    url = `${url}&accountIds=${accountId}`;
  }
  if (eventTypeId && typeof eventTypeId === 'string') {
    url = `${url}&eventTypeId=${eventTypeId}`;
  }

  if (isInviteOnly) {
    url = `${url}&isInviteOnly=${isInviteOnly}`;
  }

  if (onlyAvailable) {
    url = `${url}&excludeFullyBooked=true`;
  }

  const result = await axios.get(url, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
