import moment from 'moment';
import Cookies from 'universal-cookie';
import browserStore from 'store2';
import { datadogRum } from '@datadog/browser-rum';
import ReactGA from 'react-ga4';
import { store, persistor } from '../store';
import {
  clearFailedAuthCount,
  incrementFailedAuthCount,
  resetUserState,
  setAuthError,
  updateAuthDetails,
  updateUserDetails,
  updateUserSettings,
} from '../actions/userActions';
import { cookieNames, localStorageNames } from '../constants/storageNames';
import {
  getUserLoginProfile,
  login,
  logout,
  passwordlessLogin,
  renewSession,
  twoFactorLogin,
} from '../../api/AuthAPI/AuthAPI';
import { updateCompanyDetails } from '../actions/companyActions';
import { addJobSiteConfig } from '../actions/jobSiteActions';
import { setFunnelStages } from '../actions/funnelActions';
import { addAtsForm } from '../actions/atsFormActions';
import { setStyles } from '../actions/styleActions';
import { requestStatuses } from '../constants/requestStatuses';
import { fetchNotifications } from '../actions/eventNotificationActions';
import { retryableAPICall } from '../../api/common-api-utils';
import { resetVacancyState } from '../actions/vacancyActions';

const cookies = new Cookies();

export function storeLogoutUrl() {
  // fetch url before redirect
  const {
    location: { href, origin },
  } = window;
  // store url of page that called logout
  browserStore(localStorageNames.LOGOUT_URL, href.replace(origin, ''));
}

export function redirectToLogin() {
  storeLogoutUrl();
  window.history.pushState({}, '/login', '/login');
  window.history.go(0);
}

export function setUserData(userDetailsData) {
  const userDetails = userDetailsData.details;
  const {
    data: { accountAccess },
  } = userDetails;
  const userSettings = userDetailsData.settings;
  const defaultAccount = accountAccess.find((acc) => acc.default === true);

  datadogRum.setUser({
    email: userDetails.email,
    id: userDetails.id,
    name: `${userDetails.firstName} ${userDetails.lastName}`,
  });

  ReactGA.set({ userId: userDetails.id });

  userDetails.data.accountAccess = userDetails.data.accountAccess.map((acc) => ({
    accountId: acc.id,
    accountName: acc.name,
  }));

  if (defaultAccount) {
    userDetails.activeAccountId = defaultAccount.id;
    userDetails.activeAccountName = defaultAccount.name;
  }

  store.dispatch(updateUserDetails(userDetails));
  store.dispatch(updateUserSettings(userSettings));

  return true;
}

export function setNotifications(result) {
  // We want to log that its failed to fetch notifications
  // but want to continue with the login process
  try {
    store.dispatch(fetchNotifications(result));
  } catch (e) {
    console.log(e);
  }

  return true;
}

export function setCompanyData(companyData) {
  if (typeof companyData === 'string') return false;

  const { id, formattedName, urlIdentifier, profile, contactEmail } = companyData;

  const { logoFullUrl } = profile;

  store.dispatch(
    updateCompanyDetails({
      companyId: id,
      companyName: formattedName,
      companyLogo: logoFullUrl,
      companyIdentifier: urlIdentifier,
      contactEmail,
    }),
  );

  return true;
}

export function setJobSite(jobSiteConfig) {
  if (typeof jobSiteConfig === 'string') return false;

  const { baseUrl, vacancyBaseUrl, formattedName, accountJobsiteDetails } = jobSiteConfig;

  store.dispatch(
    addJobSiteConfig({
      baseUrl,
      vacancyBaseUrl,
      formattedName,
      accountJobsiteDetails,
    }),
  );

  return true;
}

export function addFunnelStages(funnelStages) {
  if (typeof funnelStages === 'string') return false;

  store.dispatch(
    setFunnelStages({
      stages: funnelStages,
    }),
  );

  return true;
}

export function setAtsForms(createVacancyForm) {
  if (typeof createVacancyForm !== 'string') {
    store.dispatch(
      addAtsForm({
        ...createVacancyForm,
        formType: 'createVacancyForm',
      }),
    );
  } else {
    console.log('Error getting create vacancy form');
    return false;
  }
  return true;
}

export function setAccountStyles(styleObj) {
  if (styleObj && typeof styleObj === 'object' && Object.keys(styleObj).length) {
    store.dispatch(setStyles(styleObj));
  } else {
    return false;
  }
  return true;
}

export async function refreshAuthProfile(authDetails) {
  const { dispatch } = store;
  const { token, refreshToken, expiresIn, ulToken } = authDetails;

  dispatch(
    updateAuthDetails({
      isAuthenticated: true,
      token,
      refreshToken,
      expiresAt: moment().add(expiresIn, 'seconds'),
      ulToken,
    }),
  );
}

export function checkPermissions(requiredPermissions = []) {
  // ¯\_(ツ)_/¯
  // not array you're not getting in
  if (!Array.isArray(requiredPermissions)) return false;
  // no need to run check loops if no permissions to check
  if (requiredPermissions.length === 0) return true;

  // get user's designated roles
  const roles = store.getState().userData.userDetails.roles || [];

  // for every item in requiredPermissions array
  // if some match allow access
  const perMatch = requiredPermissions.some((perm) => {
    // if combined permission e.g. perm1&&perm2&&perm3 all have to match
    if (~perm.indexOf('&&')) return perm.split('&&').every((p) => roles.includes(p));
    // otherwise just find it in the roles array
    return roles.includes(perm);
  });

  return perMatch;
}

export function goToLastUrl(navigate) {
  const { referrer } = document;
  const {
    location: { origin },
  } = window;
  const allowDashboard = checkPermissions(['dashboard:view']);
  const dashboardUrl = '/dashboard';
  let defaultPageUrl = '/vacancy';
  let lastUrl = browserStore.get(localStorageNames.LOGOUT_URL);

  // if logout url is dashbaord but no permission
  if (!allowDashboard && lastUrl === dashboardUrl) {
    // redirect to default
    lastUrl = defaultPageUrl;
  }

  if (!lastUrl && referrer.includes(origin)) lastUrl = referrer.replace(origin, '');

  if (lastUrl && lastUrl !== '/login' && lastUrl !== '/password-recovery' && lastUrl !== '/2FA') {
    // clear stored data
    browserStore.remove(localStorageNames.LOGOUT_URL);
    navigate(lastUrl);
  } else {
    if (allowDashboard) defaultPageUrl = dashboardUrl;
    navigate(defaultPageUrl);
  }
}

export async function doTwoFactorLogin(code, tfId, trustBrowser, rememberMe, onError, navigate) {
  const { dispatch } = store;
  const result = await retryableAPICall(() => twoFactorLogin(code, tfId, trustBrowser));

  const {
    refresh_token: refreshToken,
    expires_in: expiresIn,
    access_token: accessToken,
    atsUserProfile,
    twoFactorTrustId,
    ulToken,
  } = result;

  if (typeof result === 'string') {
    return result;
  }

  if (twoFactorTrustId) {
    cookies.set(cookieNames.TWO_FACTOR_TRUST_ID, twoFactorTrustId, { maxAge: 2592000 });
  }

  await refreshAuthProfile({
    token: accessToken,
    refreshToken,
    expiresIn,
    ulToken,
  });
  setUserData(atsUserProfile);

  try {
    const loginProfileData = await getUserLoginProfile();

    if (typeof loginProfileData === 'string') {
      dispatch(setAuthError(requestStatuses.GENERAL_ERROR));
      onError();
    } else {
      setCompanyData(loginProfileData.company);
      setJobSite(loginProfileData.jobsiteDetails);
      setAtsForms(loginProfileData.createVacancyForm);
      setNotifications(loginProfileData.notifications);
      setAccountStyles(loginProfileData.style);
      addFunnelStages(loginProfileData.funnelStages);

      if (rememberMe) {
        cookies.set(cookieNames.REMEMBER_ME, refreshToken);
      } else {
        browserStore.remove(localStorageNames.USERNAME);
      }

      goToLastUrl(navigate);
    }
  } catch (e) {
    dispatch(setAuthError(requestStatuses.GENERAL_ERROR));
    onError();
  }
}

export async function doLogin(
  username,
  password,
  rememberMe,
  onError,
  twoFactorTrustId,
  passwordlessToken,
  idpData,
  navigate,
  onLoginCallback,
) {
  const { dispatch } = store;

  let result;

  if (passwordlessToken) {
    result = await passwordlessLogin(passwordlessToken, twoFactorTrustId);
  } else if (idpData) {
    result = idpData.loginResponse;
  } else {
    result = await login(username, password, twoFactorTrustId);
  }

  const {
    refresh_token: refreshToken,
    expires_in: expiresIn,
    access_token: accessToken,
    atsUserProfile,
    twoFactorId,
    token_type,
    status,
    ulToken,
  } = result;

  if (typeof result === 'string') {
    dispatch(setAuthError(result));
    dispatch(incrementFailedAuthCount());
    onError();
    return;
  }
  if (status === 242) {
    window.history.pushState({}, `/2FA?id=${twoFactorId}`, `/2FA?id=${twoFactorId}`);
    window.history.go(0);
  } else if (status === 202) {
    window.history.pushState({}, `/pending`, `/pending`);
    window.history.go(0);
  } else if (status === 203) {
    window.history.pushState({}, `/password-change-required`, `/password-change-required?reason=${token_type}`);
    window.history.go(0);
  } else {
    await refreshAuthProfile({
      token: accessToken,
      refreshToken,
      expiresIn,
      ulToken,
    });
    dispatch(clearFailedAuthCount());
    setUserData(atsUserProfile);

    if (ulToken && ulToken.length > 0) {
      loadUserListScript(ulToken);
    }

    // Send login event
    ReactGA.event({
      action: 'LOGIN',
      category: 'USER_ENGAGEMENT',
      label: 'LOGIN_SUCCESS',
    });

    try {
      const loginProfileData = await getUserLoginProfile();

      if (typeof loginProfileData === 'string') {
        dispatch(setAuthError(requestStatuses.GENERAL_ERROR));
        onError();
      } else {
        setCompanyData(loginProfileData.company);
        setJobSite(loginProfileData.jobsiteDetails);
        setAtsForms(loginProfileData.createVacancyForm);
        setNotifications(loginProfileData.notifications);
        setAccountStyles(loginProfileData.style);
        addFunnelStages(loginProfileData.funnelStages);

        if (rememberMe) {
          cookies.set(cookieNames.REMEMBER_ME, refreshToken);
          browserStore(localStorageNames.USERNAME, username);
        } else {
          browserStore.remove(localStorageNames.USERNAME);
        }

        if (onLoginCallback) {
          return;
        } else {
          goToLastUrl(navigate);
        }
      }
    } catch (e) {
      dispatch(setAuthError(requestStatuses.GENERAL_ERROR));
      onError();
    }
  }
}

export function loadUserListScript(userListUserToken) {
  window.userlist =
    window.userlist ||
    function () {
      (window.userlist.q = window.userlist.q || []).push(arguments);
    };

  // Inject the Userlist script into the page with the generated user token
  const script = document.createElement('script');
  script.src = 'https://js.userlist.com/v1';
  script.async = true;
  script.dataset.userlist = userListUserToken;

  document.body.appendChild(script);
}
export async function doRenewSession(refreshToken) {
  const { userData } = store.getState();
  const result = await renewSession(refreshToken);

  if (typeof result === 'string') return false;

  await refreshAuthProfile({
    token: result.access_token,
    refreshToken: userData.userAuth.refreshToken || refreshToken,
    expiresIn: result.expires_in,
    ulToken: result.ulToken,
  });

  if (result?.ulToken && result.ulToken.length > 0) {
    loadUserListScript(result.ulToken);
  }
  if (!userData.userAuth.token) await setUserData();

  return true;
}

export async function doLogout() {
  // @todo better way to destroy state?
  cookies.remove(cookieNames.REMEMBER_ME, { path: '/' });
  persistor.purge();
  redirectToLogin();

  await logout();

  // Send logout event
  ReactGA.event({
    action: 'LOGOUT',
    category: 'USER_ENGAGEMENT',
    label: 'LOGOUT_SUCCESS',
  });
  store.dispatch(resetVacancyState());
  store.dispatch(resetUserState());
}

export async function isAuthenticated() {
  const {
    userData: {
      userAuth: { expiresAt },
    },
  } = store.getState();
  const rememberMe = cookies.get(cookieNames.REMEMBER_ME);

  if (rememberMe) return doRenewSession(rememberMe);

  if (!expiresAt) return false;

  if (moment().isAfter(expiresAt)) return doRenewSession();

  return true;
}
