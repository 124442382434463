// https://codepen.io/brundolf/pen/dvoGyw?editors=0010
function expandFnc(element, elmHeight) {
  const elm = element;
  elm.style.height = `${elmHeight}px`;

  elm.addEventListener('transitionend', function transition() {
    elm.removeEventListener('transitionend', transition);
    elm.style.height = 'auto';
  });
}

function collapseFnc(element, elmHeight, collapseHeight) {
  const elementTransition = element.style.transition;
  const elm = element;
  elm.style.transition = '';

  requestAnimationFrame(() => {
    elm.style.height = `${elmHeight}px`;
    elm.style.transition = elementTransition;

    requestAnimationFrame(() => {
      elm.style.height = `${collapseHeight}px`;
    });
  });
}

// eslint-disable-next-line import/prefer-default-export
export function toggleHeight(elm, collapse, collapseHeight = 0) {
  const elmHeight = elm.scrollHeight;

  if (collapse) {
    collapseFnc(elm, elmHeight, collapseHeight);
  }
  else {
    expandFnc(elm, elmHeight);
  }
}
