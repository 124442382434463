import React, { useRef, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { getTags, getSelectedTags, applyTags, createNewTag, sortTags } from './utils';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import { setAllTags, addTag } from '../../../js/actions/tagActions';
import { IndeterminateCheckbox } from '../Forms/Custom/CommonComponents';
import { TextButton, CreateButton } from '../Buttons';
import { useMounted, useLanguage } from '../hooks';
import { Prompt } from '../Modal';
import { uniqBy } from '../../../js/utils/arrayOfObjects';
import { tagsList } from '../../../js/language/pages';

function getChangesObject(tagIdObj) {
  return Object.entries(tagIdObj).reduce(
    (acc, [tagId, tagVal]) => {
      if (tagVal === 1) {
        acc.additions.push(tagId);
      } else if (tagVal === 0) {
        acc.deletions.push(tagId);
      }
      return acc;
    },
    { additions: [], deletions: [] },
  );
}

function TagList({ tags, tagsLoaded, candidateIds, setTags, addNewTag, onChange }) {
  const isMounted = useMounted();
  const tagIdCache = useRef();
  const selectedCandidateCache = useRef();
  const { langPack: languagePack } = useLanguage(tagsList);
  const [candidateTags, setCandidateTags] = useState([]);
  const [tagState, setTagState] = useState({});
  const [tagCount, setTagCount] = useState({});
  const [promptOpen, setPromptOpen] = useState(false);
  const [additions, setAdditions] = useState([]);
  const [deletions, setDeletions] = useState([]);
  const isAllowedToCreateTags = checkPermissions(['tags:write']);

  useEffect(() => {
    if (isMounted()) {
      if (!tags.length && !tagsLoaded) {
        getTags(
          (tagArr) => setTags(tagArr),
          () => toast.error('Error fetching tags'),
        );
      }

      getSelectedTags(
        candidateIds,
        (tagArr, tagCountObj, selectedCandidateTags) => {
          // tags array for table data
          setCandidateTags(selectedCandidateTags);
          // array of selected ids
          const tagIds = tagArr.map((tag) => tag.id);
          // object for handling state of each tag
          const tagStateObj = tags.reduce((acc, { id }) => {
            // if multiple cnadidates
            if (candidateIds.length > 1) {
              // if the candidate is in the count meaning it's selected
              if (tagCountObj[id]) {
                // if count matches amount of selected candidates 1
                // else -1 meaning selected but only applied to 1 candidate
                acc[id] = tagCountObj[id] === candidateIds.length ? 1 : -1;
              }
              // else 0 meaing not selected
              else {
                acc[id] = 0;
              }
            }
            // if single cnadidate
            else {
              // just 1 or 0 depending if selected
              acc[id] = tagIds.includes(id) ? 1 : 0;
            }
            return acc;
          }, {});

          setTagState(tagStateObj);
          // for calculating indeterminate checkbox prop
          setTagCount(tagCountObj);

          // cache for managing additions and deletions
          tagIdCache.current = getChangesObject(tagStateObj);
          // cache for handling indeterminate tags weh re-applied to candidate
          selectedCandidateCache.current = selectedCandidateTags;
        },
        () => toast.error('Error fetching selected tags'),
      );
    }
  }, [candidateIds, isMounted, setTags, tags, tagsLoaded]);

  function handleChange(id, value, newTag) {
    // update state object with changed value 0 / 1 /-1
    const updatedState = { ...tagState, [id]: value };
    setTagState(updatedState);

    // get arrays of additions and deletions dependent on 0 or 1 value
    const changesObj = getChangesObject(updatedState);

    // compare with cached arrays
    const addArr = changesObj.additions.filter((tagId) => !tagIdCache.current.additions.includes(tagId));
    const deleteArr = changesObj.deletions.filter((tagId) => !tagIdCache.current.deletions.includes(tagId));
    setAdditions(addArr);
    setDeletions(deleteArr);

    // update the tags that will be applied to each candidate
    let updated = [...candidateTags];

    // for each candidate
    updated = updated.map((candidate) => {
      const { id: candidateId, tags: tagArr } = candidate;
      let updatedTags = [...tagArr];

      // if 0 remove tag
      if (value === 0) {
        updatedTags = updatedTags.filter((tag) => tag.id !== id);
      }
      // if 1 add tag
      else if (value === 1) {
        let selectedTag = tags.find((tag) => tag.id === id);
        // new tag not in tags so add separately
        if (newTag) selectedTag = newTag;
        updatedTags.push(selectedTag);
      }
      // if -1 reapply tags as they were for each candidate
      else if (value === -1) {
        const cachedCandidate = selectedCandidateCache.current.find((candObj) => candObj.id === candidateId);
        const t = [...cachedCandidate.tags].find((tag) => tag.id === id);
        if (t) updatedTags.push(t);
      }

      // remove any dupes
      return { ...candidate, tags: uniqBy(updatedTags, 'id') };
    });

    setCandidateTags(updated);
  }

  return (
    <Fragment>
      <div className={cx('group-tags', { 'no-tags': !tags.length })}>
        <div className="scroll-wrapper">
          <div className="tag-list">
            {sortTags(tags).map(({ id, name }) => (
              <div key={id} className="tag-list-cell">
                <IndeterminateCheckbox
                  id={id}
                  // 0 / 1 / -1
                  value={tagState[id]}
                  // if multiple candidates and tag applied to all selected candidates display as indeterminate
                  indeterminate={candidateIds.length > 1 && tagCount[id] && tagCount[id] < candidateIds.length}
                  labelClassName="tag-checkbox-label"
                  onChange={(val) => handleChange(id, val)}
                >
                  {name}
                </IndeterminateCheckbox>
              </div>
            ))}
          </div>
        </div>
        <div className="button-wrapper">
          <CreateButton
            size="sm"
            disabled={!isAllowedToCreateTags}
            label={languagePack.createButton}
            floatRight={false}
            action={() => setPromptOpen(true)}
          />
          {!!tags.length && (
            <TextButton
              size="sm"
              floatRight={false}
              label={languagePack.applyButton || 'Apply Tags'}
              // only need applying if the additions or deletions
              disabled={!additions.length && !deletions.length}
              action={() => {
                applyTags(candidateIds, {
                  removeTagIds: deletions,
                  addTagIds: additions,
                  onError: () => toast.error(languagePack.applyTagError),
                  onSuccess: () => {
                    // convert array to object for processing for updated table data
                    const tagsObj = candidateTags.reduce(
                      (acc, tag) => ({
                        ...acc,
                        // update the name / label for table
                        [tag.id]: tag.tags.map(({ id, name }) => ({ id, label: name })),
                      }),
                      {},
                    );
                    onChange(tagsObj);
                  },
                });
              }}
            />
          )}
        </div>
        <div className="px-2 pb-2 text-center">
          <Link to="/settings/tags">{languagePack.manageLinkLabel}</Link>
        </div>
      </div>
      <Prompt
        title={languagePack.promptTitle}
        isOpen={promptOpen}
        onCancel={() => setPromptOpen(false)}
        closeOnOkay={false}
        onOkay={(val) => {
          if (val.length) {
            createNewTag(
              val,
              (tag) => {
                // update redux
                addNewTag(tag);
                // close the prompt
                setPromptOpen(false);
                toast.success(languagePack.tagCreatedSuccess);
                // delay to give redux time to update
                setTimeout(() => handleChange(tag.id, 1, tag), 500);
              },
              (err) => {
                let msg = languagePack.createTagError;

                if (err === 'ALREADY_EXISTS_ERROR') {
                  msg = languagePack.createTagErrorExists;
                }

                toast.error(msg);
              },
            );
          }
        }}
      />
    </Fragment>
  );
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  tagsLoaded: PropTypes.bool,
  candidateIds: PropTypes.arrayOf(PropTypes.string),
  setTags: PropTypes.func,
  addNewTag: PropTypes.func,
  onChange: PropTypes.func,
};

TagList.defaultProps = {
  tags: [],
  tagsLoaded: false,
  candidateIds: [],
  setTags: () => {},
  addNewTag: () => {},
  onChange: () => {},
};

function mapStateToProps(state) {
  const { tags } = state;
  return {
    tags: tags.tags || [],
    tagsLoaded: tags.tagsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTags: (tags) => {
      dispatch(setAllTags(tags));
    },
    addNewTag: (tag) => {
      dispatch(addTag(tag));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
