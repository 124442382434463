import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function SpanBtn({ className, children, onClick }) {
  return (
    <span
      className={cx('event-btn', className)}
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      tabIndex="0"
    >
      {children}
    </span>
  );
}

SpanBtn.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.string,
};

SpanBtn.defaultProps = {
  onClick: () => { },
  className: null,
  children: null,
};

export default SpanBtn;
