import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import * as utils from '../../utils';
import { RangePicker } from '../../../Base/Forms/Custom/DatePicker';
import { useMounted } from '../../../Base/hooks';
import CreateCalendarSlot from './InstantEventCreation/CreateCalendarSlot';
import styled from 'styled-components';

const StyledSelectWrapper = styled.div`
  ${({ isCentered }) => `
  margin-left: ${isCentered ? 'unset' : 'calc(16.66667% + 0.5em)'};
  `}
  .date-picker > div:first-of-type {
    min-width: 16rem;
  }
`;

async function fetchOptions(id, callback, startDate, endDate, accountId, eventTypeId, isInviteOnly) {
  const eventsArr = await utils.fetchUtils.getOptions(id, [
    startDate,
    endDate,
    accountId,
    undefined,
    eventTypeId,
    isInviteOnly,
  ]);
  const opts = eventsArr.map((evObj) => {
    const { id: evId, eventName, allDay, startDate: sDate, endDate: eDate } = evObj;
    const evStart = moment.utc(sDate).local();
    const evEnd = moment.utc(eDate).local();

    return {
      label: `${eventName}: ${utils.eventUtils.getEventTitle({ allDay, endDate: evEnd, startDate: evStart })}`,
      startDate: sDate,
      value: evId,
    };
  });

  callback(opts);
}

function SlotDropdown({
  required,
  options,
  onChange,
  openSlotCreationModal,
  value,
  isMulti,
  placeholder,
  isClearable,
  disabled,
  className,
  id,
}) {
  const customOptions = [{ label: 'Create new slot', value: '__create_new__' }, ...options];

  const handleChange = (selectedOption) => {
    if (selectedOption.value === '__create_new__') {
      openSlotCreationModal();
    } else {
      if (Array.isArray(selectedOption)) {
        onChange(
          selectedOption.map((opt) => opt.value),
          selectedOption,
        );
      } else {
        onChange(selectedOption?.value || '', selectedOption);
      }
    }
  };

  const computeValue = () => {
    if (isMulti && Array.isArray(value)) {
      return value.map((val) => ({
        label: customOptions.find((opt) => opt.value === val)?.label || val,
        value: val,
      }));
    }
    return customOptions.find((opt) => opt.value === value) || null;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.data.value === '__create_new__' ? 'bold' : 'normal',
      backgroundColor:
        state.data.value === '__create_new__' ? (state.isFocused ? '#222' : '#fff') : state.isFocused ? '#ccc' : '#fff',
      color: state.isFocused ? '#d4d2d2' : '#000', // White text when focused, black otherwise
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontWeight: state.data.value === '__create_new__' ? 'bold' : 'normal',
    }),
  };

  return (
    <FormGroup>
      <Label>
        Choose slot
        {required && <span style={{ color: 'red' }}> *</span>}
      </Label>
      <Select
        id={id}
        className={className}
        classNamePrefix="react-select"
        options={customOptions}
        value={computeValue()}
        onChange={handleChange}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={disabled}
        placeholder={placeholder}
        styles={customStyles}
      />
    </FormGroup>
  );
}

SlotDropdown.propTypes = {
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func,
  openSlotCreationModal: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
};

SlotDropdown.defaultProps = {
  required: false,
  options: [],
  onChange: () => {},
  value: '',
  isMulti: false,
  placeholder: 'Select...',
  isClearable: true,
  disabled: false,
  className: null,
  id: null,
};

function DateRange(props) {
  return (
    <FormGroup>
      <Label htmlFor="endDate">Filter by date range</Label>
      <div>
        <RangePicker {...props} id="date-range" />
      </div>
    </FormGroup>
  );
}

function SlotSelect({
  value,
  label,
  onChange,
  required,
  disabled,
  isTouch,
  activeAccountId,
  eventTypeId,
  isInviteOnly,
  formData,
}) {
  const isMounted = useMounted();
  const [dateRange, setDateRange] = useState({
    endDate: moment(new Date()).add(1, 'month'),
    startDate: moment(new Date()),
  });

  const [isChanged, setIsChanged] = useState(false);
  const [selectOpts, setSelectOpts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      const startDate = utils.dateTimeUtils.getIsoDate(dateRange.startDate);
      const endDate = utils.dateTimeUtils.getIsoDate(dateRange.endDate);

      fetchOptions(
        'slotId',
        (opts) => {
          if (isMounted()) setSelectOpts(opts);
        },
        startDate,
        endDate,
        activeAccountId,
        eventTypeId,
        isInviteOnly,
      );
    }
  }, [isChanged, dateRange.endDate, dateRange.startDate, isMounted, eventTypeId]);

  if (!formData.eventTypeId) {
    return null;
  }

  return (
    <StyledSelectWrapper className="d-flex flex-column" isCentered={formData.inviteType === 'SELF_BOOK'}>
      {formData.inviteType === 'SELF_BOOK' || formData.inviteType === 'INSTANT' ? null : (
        <>
          {label && <h6>{label}</h6>}
          {isTouch ? (
            <>
              <DateRange
                disabled={disabled}
                endDate={dateRange.endDate}
                isTouch
                onChange={({ startDate, endDate }) => setDateRange({ endDate, startDate })}
                startDate={dateRange.startDate}
              />
              <SlotDropdown
                disabled={disabled}
                onChange={onChange}
                options={selectOpts}
                required={required}
                value={value}
                openSlotCreationModal={() => setIsModalOpen(true)}
              />
            </>
          ) : (
            <div className="date-picker">
              <DateRange
                disabled={disabled}
                endDate={dateRange.endDate}
                onChange={({ startDate, endDate }) => setDateRange({ endDate, startDate })}
                startDate={dateRange.startDate}
                className="date-selector"
              />
              <SlotDropdown
                disabled={disabled}
                onChange={onChange}
                options={selectOpts}
                required={required}
                value={value}
                openSlotCreationModal={() => setIsModalOpen(true)}
              />
            </div>
          )}
        </>
      )}
      <CreateCalendarSlot
        formData={formData}
        applicantActiveAccountId={activeAccountId}
        onChange={() => {
          setIsChanged(!isChanged);
        }}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </StyledSelectWrapper>
  );
}

SlotSelect.propTypes = {
  activeAccountId: PropTypes.string,
  disabled: PropTypes.bool,
  eventTypeId: PropTypes.string,
  isInviteOnly: PropTypes.bool,
  isTouch: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};

SlotSelect.defaultProps = {
  activeAccountId: null,
  disabled: false,
  eventTypeId: null,
  isInviteOnly: false,
  isTouch: false,
  label: null,
  onChange: () => {},
  required: false,
  value: '',
};

export default SlotSelect;
